import React, { useState, useEffect, useRef, memo } from "react";
import SekletonLearningMaterial from "../../loaders/SekletonLearningMaterial";
import SideAssemliesItems from "./SideAssemliesItems";
import { useDispatch, useSelector } from "react-redux";
import { UpdateLastLessonId, UpdateLessonType, clearActivityArr, clearAssessmentActive, clearCurrentActivity, clearCurrentCategory, clearLessonType, updateActivityArr, updateActivityBtnAction, updateAssembliestItems, updateCurrentActivity, updateCurrentAssembly } from "../../store/actions";
import { TrimText } from "../common/TrimText";
import Scrollbar from "react-scrollbars-custom";
import SideSubAssembliesItems from "./SideSubAssembliesItems";

const LeasonListLearningMaterial = (props) => {
  const { moduleId, setModuleId, assembliesItems, setassembliesItems, assemblyCategoryList   } = props;
  // const [assemblies, setAssemblies] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [expandedModuleId, setExpandedModuleId] = useState(moduleId);

  // const currentAssembly = useSelector(state => state.learningMaterial.currentAssembly);
  const currentActivity = useSelector(state => state.learningMaterial.current_activity);
  const activityBtnAction = useSelector(state => state.learningMaterial.activityBtnAction);
  // const lesson_type = useSelector(state => state.learningMaterial.active_lesson_type);

  // const handleItem = (item) => {
  //   props.setLessonList(item?.lessons);
  //   dispatch(updateCurrentActivity(item?.lessons[0]?.item_id));
  // };

  // const handleNextCategory = (item) => {
  //   dispatch(updateCurrentActivity(item.module_id));
  //   dispatch(updateCurrentAssembly(item.assembly_id));
  //   dispatch(UpdateLessonType(item.item_type));
  // };

  useEffect(() => {
    if (assemblyCategoryList  ) {
      const matchedItems = assemblyCategoryList ?.assembliesItems?.length && assemblyCategoryList ?.assembliesItems?.filter(item => item?.item_id === moduleId);
      let matchedData = matchedItems[0]?.lessons || [];
      props.setLessonList(assemblyCategoryList );
      const filteredLessons = matchedData?.length && matchedData?.filter(lesson => lesson?.item_type !== undefined);
      if(filteredLessons?.length > 0){
        if(activityBtnAction !== 'back') {
          //just creating a condition such that after module is changed 
          // on continue it goes to the first lesson if the first lesson has no sub-children and if it has sub-children then it goes to the first sub-children of the first lesson 
          // and the above is vice-versa for the back button
          let x = 0
          while(!filteredLessons[x]?.sub_children.length && filteredLessons[x]?.item_type == 3){
            x++
            if(filteredLessons[x]?.sub_children.length){
              break;
            }
          }
          dispatch(updateCurrentActivity(filteredLessons[x]?.sub_children.length ? filteredLessons[x]?.sub_children[0]?.item_id : filteredLessons[x]?.item_id));
          dispatch(UpdateLessonType(filteredLessons[x]?.item_type));
        }else{
          let x = 1;
          while(!filteredLessons[filteredLessons.length - x]?.sub_children.length && filteredLessons[filteredLessons.length - x]?.item_type == 3){
            x++;
            if(filteredLessons[filteredLessons.length - x]?.sub_children.length){
              break;
            }
          }
          let lastFiltered = filteredLessons[filteredLessons.length - x]
          let idForBackIfsubChild = lastFiltered?.sub_children[lastFiltered?.sub_children.length - 1]?.item_id
          dispatch(updateCurrentActivity(lastFiltered?.sub_children.length ? idForBackIfsubChild : lastFiltered?.item_id));
          dispatch(UpdateLessonType(filteredLessons[filteredLessons.length - x]));
        }      
      }else{
        dispatch(clearActivityArr());
        dispatch(clearLessonType());
      }
      console.log(">>>>>",filteredLessons)
    }
  }, [moduleId]);


  // const handleAccordionToggle = (item) => {
  //   setModuleId(item?.item_id);
  
  //   // Filter out lessons with type 3 and get the first one
  //   const firstLesson = item?.lessons?.find(lesson => lesson.item_type !== 3);
  
  //   // If a valid lesson is found, dispatch the updateCurrentActivity action
  //   if(firstLesson.length > 0){
  //       dispatch(updateCurrentActivity(firstLesson[0]?.item_id));
  //       dispatch(UpdateLessonType(firstLesson[0]?.item_type));
  //     }
  // };

  const handleAccordionToggle = (itemId) => {
    dispatch(updateActivityBtnAction('continue'))
    setModuleId(itemId);
    if (expandedModuleId === itemId) {
      setExpandedModuleId(null);
    } else {
      setExpandedModuleId(itemId);
    }
  };
  

  return (
    <>
      {loading && <SekletonLearningMaterial />}
      {assemblyCategoryList  && !loading ? (
        <div className="assembly-items-parent d-flex flex-column h-100" id={"accord" + assemblyCategoryList ?.id} key={assemblyCategoryList .id}>
          <div className="assebly__top__content">
            <h3 className="assembly-name" title={assemblyCategoryList ?.name}>{assemblyCategoryList ?.name}</h3>
            <p className="assembly-demmy-text" title={assemblyCategoryList ?.item_description}>{assemblyCategoryList ?.item_description}</p>
          </div>
          <Scrollbar>
            {assemblyCategoryList ?.assembliesItems?.length ? <div className="assembly-list">
              <div className="learning-material-listing">
                {assemblyCategoryList ?.assembliesItems?.map((items) => (
                  <div className={`learning-material-card `} key={items?.item_id}>
                    <div className={`hd-main d-flex flex-wrap learning-box ${items?.lessons?.length < 1 ? 'disabled' : null}`}>
                      <div
                        className={`learning-material-head borderprimary assembly-parent `}
                        {...(items?.lessons?.length > 0 ? {
                          'data-toggle': 'collapse',
                          'data-target': `#accordionBody${items?.item_id}`,
                          'aria-expanded': expandedModuleId === items?.item_id ? "true" : "false",
                          'aria-controls': `accordionBody${items?.item_id}`,
                          id: `category${items?.item_id}`
                        } : {})}
                        onClick={(e) => items?.lessons?.length > 0 ? handleAccordionToggle(items?.item_id) : null}
                      >
                        <div className="lesson__heading__div flex-row justify-content-between" title={items?.item_name}>
                          <h3 className="lesson__heading__title" title={items?.item_name}>{TrimText(items?.item_name, 28)}</h3>
                          <div className="header-status">
                            <div className="status-gol">
                              <i className={`fal ${expandedModuleId === items?.item_id ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                            </div>
                          </div>
                        </div>
                        {items?.item_desc ? <p className="category__desc__text" title={items?.item_desc}>{TrimText(items?.item_desc, 75)}</p> : null}
                        <li className="material-info deep-text">
                          {[
                            { title: "Videos", icon: "fa-video", data: items?.tatalvideos },
                            { title: "Documents", icon: "fa-file", data: items?.totalDocuments },
                            { title: "Quiz", icon: "fa-clipboard-list", data: items?.totalQuiz },
                            { title: "Link", icon: "fa-external-link", data: items?.totalLinks },
                          ].map(({ title, icon, data }, index) =>
                            data > 0 && (
                              <React.Fragment key={index}>
                                <i title={title} className={`fal ${icon}`} />
                                {data}
                              </React.Fragment>
                            )
                          )}
                        </li>

                        {items?.lessons?.length > 0 ? <div className="course-item-progress">
                          <div className="progress" style={{ height: "11px" }} title="Progress">
                            <div
                              className="progress-bar progress-bar-text"
                              role="progressbar"
                              style={{ width: items?.percentage + "%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {items?.percentage || "0"}%
                            </div>
                          </div>
                        </div> : null}
                      </div>
                      <div
                        className={`learning-material-body assembly-items collapse ${(moduleId === items?.item_id) || ("") ? "show" : ""}`}
                        id={`accordionBody${items?.item_id}`}
                        aria-labelledby={`category${items?.item_id}`}
                        data-parent={`#accord${ assemblyCategoryList?.id}`}
                      >
                        <ul className="sub-ul">
                          {items?.lessons?.map((data, index) => (
                            <SideAssemliesItems lessonData={data} key={index} lessonList={props.lessonList} setLessonList={props.setLessonList} parentAccId={`#accordionBody${items?.item_id}`} />
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
              : <div className="learning-material-listing" id="accordion" role="tablist" aria-multiselectable="true">
                <div className="subject-header">
                  <div className="subject-sub-header list-header">
                    <h3>No Content Available.</h3>
                  </div>
                </div></div>}
          </Scrollbar> 
        </div>
      ) : (
        <div className="learning-material-listing" id="accordion" role="tablist" aria-multiselectable="true">
          <div className="subject-header">
            <div className="subject-sub-header list-header">
              <h3>No Content Available.</h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(LeasonListLearningMaterial);
