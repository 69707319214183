import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { clearAssessmentActive, clearToggles, modalOpen, setIsExamLaunched, setUnreadChatNotificationCount, updateActivityBtnAction, updateAssessmentActive } from "../../store/actions";
import { GetPostFeedMessagesPaginate, readAllCommentMessages,GetMyUnreadChatNotificationCount, GetCourseStatistics, GetCourseResourceDocumentsCount } from "../../services/QualificationService";
import { getToken } from "../../utils/Auth";
import SubjectHeadBox from "../course/SubjectHeadBox";
import { toast } from "react-toastify";
import FeedMessageForm from "../feed/FeedMessageForm";
import FeedMessages from "../feed/FeedMessages";
import SkeletonMessage from "../../loaders/SkeletonMessage";
import SubjectAssignments from "../course/SubjectAssignments";
import SubjectAssignmentsLatest from "../course/SubjectAssignmentsLatest";
import SubjectAssemblies from "../course/SubjectAssemblies";
import TeamBox from "../course/TeamBox";
import Assignment from "./Assignment";
import Appeal from "./Appeal";
import SocketConnection from "../../utils/SocketConnection";
import SkeletonSubjectHeadBox from "../../loaders/SekletonSubjectHeadBox";
import SkeletonReRegistrationList from "../../loaders/SkeletonReRegistrationList";
import OnlineClasses from "../course/OnlineClasses";
import $ from "jquery";
import ViewQuiz from "./quiz/ViewQuiz";
import QuizDescription from "./quiz/QuizDescription";
import ViewQuestion from "./quiz/ViewQuestion";
import SubmitConfirmation from "./quiz/SubmitConfirmation";
import ReviewQuiz from "./quiz/ReviewQuiz";
import _, { debounce } from "underscore";
import "../../assets/css/chat.css";
import MentoredSubject from "./MentoredSubject";
import ReactHtmlParser from "react-html-parser";
import ReactDynamicModal from "react-draggable-resizable-modal";
import ReactPlayer from "react-player";
import { Scrollbar } from "react-scrollbars-custom";
import { getRandomInt } from "../../utils/commonFunction";
import Swal from "sweetalert2";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import FeedToolBar from "../feed/FeedToolBar";
import SharedFileList from "../feed/SharedFileList";
import MentoredSubjectNotes from "./MentoredSubjectNotes";
import CompleteMentoredSubject from "./CompleteMentoredSubject";
import UpdateMentoredSubject from "./UpdateMentoredSubject";
import { useParams } from "react-router-dom";
import { ADMIN_PASSWORD } from "../../utils/Constants";
import { ta } from "date-fns/locale";
import GlobalCalendar from "./GlobalCalendar";
import { Portal } from "semantic-ui-react";
import AssemblyItemBox from "./AssemblyItemBox";
import { GetLearningMaterial, getLearningMaterialAdmin, GetLearningMaterialAdmin, getLMContentAssemblies } from "../../services/AssembliesService";
import { ProgramIcons } from "./CommonCourseComponents";
import { reverse } from "@tensorflow/tfjs";

let subscription, connection, typeEvent;
const SubjectContent = (props) => {
  const {setshow, show}=props
  const user = useSelector((state) => state.user);
  const { aid, sid } = useParams()
  const dispatch = useDispatch();
  const {password} = useParams();
  const [feeds, setFeeds] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assembliesDataLoad, setAssembliesDataLoad]=useState(true)
  const [showAssignment, setShowAssignment] = useState(false);
  const [showAppeal, setShowAppeal] = useState(false);
  const [welcome, setWelcome] = useState(null);
  const [typing, setTyping] = useState(false);
  const [assessmentId, setAssessmentId] = useState(props.assessmentId);
  const [QuizId, setQuizId] = useState("");
  const [rId, setrId] = useState("");
  const [tab, setTab] = useState("overview");
  const [videoId, setVideoId] = useState("")
  const [modalOpen, setModalOpen] = useState("")
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [chatUnreadCount, setChatUnreadCount] = useState(0)
  const [reactUnreadCount, setReactUnreadCount] = useState(0)
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  // const [toggleScroll, setToggleScroll] = useState(false);
  const [openReplyId, setOpenReplyIds] = useState('');
  const [editableArr, setEditableArr] = useState([]);
  const [editablePost, setEditablePost] = useState('');
  const [showNewStatus, setShowNewStatus] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [reRender, setReRender] = useState(1);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [showBlankIcon, setShowBlankIcon] = useState(false);
  // const [statistics, setStatistics] = useState({
  //   learning_material_percentage:0,
  //   assessment_percentage:0,
  //   complete_percentage:0
  // });
  const [statistics, setStatistics] = useState(props.subjectContent?.progress);
  const assignmentLink = useRef(null);
  const assignmentsLink = useRef(null);
  const scrollbarsRef = useRef(null);
  const [lastY, setLastY] = useState(0);
  const history = useHistory();
  const [isSubmitDocsAss, setIsSubmitDocsAss] = useState(null);
  const [feedSearch, setFeedSearch] = useState("");
  const { examLaunched } = useSelector(state=>state.examLaunched);
  const [cancelTokenSource, setCancelTokenSource] = useState('');
  const [classFilter, setClassFilter] = useState(0);
  const [replyFilters, setReplyFilter] = useState(0);
  const [mentionFilters, setMentionFilter] = useState(0);
  const [unReadMessages, setUnReadFilter] = useState(0);
  const [assessmentFilter, setAssessmentFilter] = useState(0);
  const [notificationFilter, setNotificationFilter] = useState(0);
  const [ assembliesList, setAssembliesList] = useState([]);
  const [updateLearningMaterialData, setUpdateLearningMaterialData]=useState({state:null, id:null})
  const currentActivity = useSelector(state => state.learningMaterial.current_activity);

  useEffect(() => {
    if(aid && aid != undefined && aid != 0){
      setAssessmentId(aid)
    }
  }, [aid])

  useEffect(()=>{
    setStatistics(props.subjectContent?.progress)
  },[props.subjectContent])
 
  const scrollBottom = (behavior = "auto") => {
    const element = document.getElementById("content");
    if(element)
      element.scrollIntoView({ behavior: behavior, block: "end", inline: "nearest" });
  }

  useEffect(() => {
    connection = SocketConnection.connect();

    // $.getScript("https://cdn.jsdelivr.net/npm/easy-responsive-tabs@0.0.2/js/easyResponsiveTabs.min.js", function(data, textStatus) {
    //         $('.parentHorizontalTabInformaton').easyResponsiveTabs();
    // }) ;
    // $('.parentHorizontalTabInformaton').easyResponsiveTabs();
  }, []);

  //if tab or currentActivityUpdates-close the quize
  useEffect(()=>{
    setshow({ cards: false, list: true, question: false, desc: true,attempt:false,review:false})
  },[currentActivity])

  useEffect(()=>{
    setshow({ cards: true, list: false, question: false, desc: false,attempt:false, review:false})
  },[tab])
  
  const getMessages = async (c_id=0, m_id=0) => {
    const source = await axios.CancelToken.source();
    setCancelTokenSource(source);
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request made');
    }
    setFeeds([])
    setLoader(true)
    setLastPage(0);
    setPage(0);
    const payload = { 
      room_id: props.subjectContent.subject.id, 
      user_id: user.id, 
      search:feedSearch,
      message_id:m_id,
      comment_id:c_id,
      classFilter,
      replyFilters,
      mentionFilters,
      unReadMessages,      
      assessmentFilter,
      notificationFilter
    }
    GetPostFeedMessagesPaginate(payload, 1, 10, source.token)
      .then(data => {
        setCancelTokenSource('');
        setLastPage(data.data.lastPage);
        setPage(data.data.page);
        setFeeds(data.data.data);
        setLoader(false);
        scrollBottom();
        setLastY(scrollbarsRef?.current?.scrollHeight ? scrollbarsRef.current.scrollHeight : 0);
        if(data.data.data?.length==0){
          setShowBlankIcon(true)
        }
        else{
          setShowBlankIcon(false)
        }
      })
      .catch(err => {
        setCancelTokenSource('');
        setLoader(false)
      }).finally(()=>{
        setLoader(false);
      })
  }

  const getLearningMaterialData = (sid) => {
    let mounted = true
    // if (props.isAdmin == 1) {
    //   GetLearningMaterialAdmin({ sid: sid })
    //     .then((res) => {
    //       if (mounted && res.data.assemblies) {
    //         setAssembliesList(res.data.assemblies)
    //       }
    //       setLoading(false);
    //     })
    //     .catch((error) => { });
    // } else {

      let responsePromise;
      if (props.isAdmin == 1) {
        responsePromise = getLearningMaterialAdmin({ sid });
      } else {
        responsePromise = getLMContentAssemblies({ sid });
      }      
      responsePromise
        .then((res) => {
          if (mounted && res.data.assemblies) {
            setAssembliesList(res.data.assemblies)
          }
          setLoading(false);
          setAssembliesDataLoad(false)
        })
        .catch((error) => { });

    // }


    return function cleanup() {
      mounted = false
    }
  };

  useEffect(() => {    
    if(tab == "assembly"){
      getLearningMaterialData(props.subjectId);
    }
  }, [tab, sid,])

  useEffect(() => {
    let mounted = true;
  
    const fetchAssemblies = async () => {
      if (!sid) return;
  
      try {
        // setLoading(true);
        setAssembliesDataLoad(true);        
        const res = await getLMContentAssemblies({ sid });
        
        if (mounted && res.data?.assemblies) {
          setAssembliesList(res.data.assemblies);
          let data = res.data.assemblies
          let filteredData = data.filter(item => item.id == updateLearningMaterialData.id)
          props. setAssemblyCategoryList(filteredData[0])
          props.setLessonList(filteredData[0])
        }
      } catch (error) {
        if (mounted) {
          // Optionally handle error state here
          console.error('Failed to fetch assemblies:', error);
        }
      } finally {
        if (mounted) {
          setLoading(false);
          setAssembliesDataLoad(false);
        }
      }
    };
  
    if (updateLearningMaterialData.state) {
      fetchAssemblies()
        .then(() => {
          props.getSubContentMethod()
        })
        .catch((err) => {
          console.error("Error fetching assemblies:", err);
        });
    } 
  
    // Cleanup function
    return () => {
      mounted = false;
    };
  }, [updateLearningMaterialData]); 
  
  useEffect(() => {
    setPage(0)
    setFeeds([])
    const cancelTokenSources = [];
    const getFeedMessages = async () => {
      setFeeds([])
      setLoader(true)
      setLastPage(0);
      setPage(0);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      const payload = { 
        room_id: props.subjectContent.subject.id, 
        user_id: user.id, 
        search:feedSearch,
        classFilter,
        replyFilters,
        mentionFilters,
        unReadMessages,
        assessmentFilter,
        notificationFilter
      }
      try {
        const res = await GetPostFeedMessagesPaginate(payload, 1, 10, source.token);
        if(res.status === 200){
          setLastPage(res.data.lastPage);
          setPage(res.data.page);
          setFeeds(res.data.data);
          setLoader(false);
          scrollBottom();
          setLastY(scrollbarsRef?.current?.scrollHeight);
          if(res.data.data?.length==0){
            setShowBlankIcon(true)
          }
          else{
            setShowBlankIcon(false)
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoader(false);
        }
      }finally{
        setLoader(false);
      }           
    }    
    if (password!=ADMIN_PASSWORD && reactUnreadCount) {
      getFeedMessages();
    }
        
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [props.subjectContent.subject.id, reRender, feedSearch, classFilter, replyFilters, mentionFilters, unReadMessages, assessmentFilter, notificationFilter])


  useEffect(()=>{
    setChatUnreadCount(props.subjectContent.messageCount)
    setShowNewStatus(false)
    setOpenReplyIds('')
    setEditableArr([])
  },[props.subjectContent.subject.id, reRender,])

  const reduceCount = (count, room_id) => {
    setChatUnreadCount(chatUnreadCount - count)
    props.reduceCount(count, room_id)
  }

  const updateEdited = (data) => {
    setFeeds((pre) => {
      const targetMessage = pre.findIndex(item => item.id === data.id);
      pre[targetMessage].message = data.message
      pre[targetMessage].subject = data.subject
      pre[targetMessage].important = data.important
      pre[targetMessage].attachments = data.attachments
      return [...pre]
    });
  }

  const userSubscription = async () => {
    if (props.subjectContent.subject) {
      if (subscription) subscription.close();

      subscription = await SocketConnection.subscribe(
        `adminchat:${props.subjectContent.subject.id}`,
        "test"
      );

      await subscription.on("message", (message) => {
        // console.log("== in discussion", message);

        if (message && message.message_type && message.message_type == 'edited') {
          updateEdited(message)
        } else {
          setFeeds((prev) => [...prev, message]);
          setTimeout(() => { scrollBottom("smooth"); }, 1000);
        }
        return false;
      });

      await subscription.on("new:user", (connected_user) => {
        if (_.findWhere(users, connected_user.id) == null) {
          setUsers((users) => [...users, connected_user.id]);
        }
      });

      await subscription.on("messageDelete", (message) => {
        // console.log("messageDelete socket-----> ", message);
        setReRender(getRandomInt(5000))

        // let arr = feeds;
        // console.log('feeds value is here ', arr, feeds)
        // arr.map((e, i) => {
        //   if (e.id === message.message_id) {
        //     arr.splice(i, 1);
        //   }
        // })
        // setFeeds(pre => [...arr]);
      });

      await subscription.on("likeDislike", (message) => {
        setFeeds((pre) => {
          const targetMessage = pre.findIndex(item => item.id === message.data.message_id);
          if(targetMessage !== -1){
            pre[targetMessage].likes = message.likes
            pre[targetMessage].dislikes = message.dislikes
            pre[targetMessage].love = message.love;
            pre[targetMessage].laugh = message.laugh;
            pre[targetMessage].surprised = message.surprised;
            pre[targetMessage].sad = message.sad;
            pre[targetMessage].angry = message?.angry;
            return [...pre]
          }
        });
      });

      if (typeEvent) typeEvent.close();
      typeEvent = await SocketConnection.subscribe(
        `admintyping:${props.subjectContent.subject.id}`,
        "test"
      );

      await typeEvent.on("typing", (message) => {
        setTyping(true);
        setTimeout(() => {
          setTyping(false);
        }, 1000);
      });
      setWelcome(props.subjectContent.subject.welcome);
    }
  };

  useEffect(() => {
    userSubscription();
  }, [props.subjectContent.subject.id]);

  useEffect(
    () => () => {
      if (subscription) {
        try{
          subscription.close();
        }catch(err){
          console.log(err);
        }
        // typeEvent.close();
      }
    },
    []
  );

  useEffect(()=>{
    if(props.backToOverview>0){
      if(props.subjectContent.subject?.subjectStatus == "Not Started"){
        setTab("overview")
      }

      if(tab!=="overview" && tab!=="feeds"){
        if (props.subjectContent.subject.convChatGroup == 1 && password!=ADMIN_PASSWORD) {
          setTab("feeds")
        }else{
          setTab("overview")
        }
        setShowAssignment(false);
      }
    }
  },[props.backToOverview])

  useEffect(() => {
    if(assessmentId){
      setTab("viewAssessment");
      setShowAssignment(true);
      // setAssessmentId(props.assessmentId);
      history.push(`/course/${props.qid}/subject/${props.subjectId}/assessment/${assessmentId}`);
    } 
    // else if (props.subjectContent.subject.convChatGroup == 1 && password!=ADMIN_PASSWORD && props.subjectContent.subject?.subjectStatus !== "Not Started" ) {
    //   // setTab("feeds")
    //   setTab("overview")
    //   setShowAssignment(false);
    // } 
    else {
      setTab("overview")
      setShowAssignment(false);
    }
  }, [props.subjectContent.subject.convChatGroup,props.assessmentId,props.subjectContent.subject.id, assessmentId])

  useEffect(() => {
    if (showAssignment) {
      assignmentLink.current.click();
    }
  }, [showAssignment]);

  // useEffect(() => {
  //   setshow({ cards: true });
  // }, [props.subjectId]);

  const TypingMessage = () => {
    //typeEvent.emit("typing", true);
  };

  const sendMessage = async (message) => {
    // return;
    message.user_id = user.id;
    message.role = 'student'
    
    subscription.emit("message", message);
  };

  const viewAssignment = (id, action) => {
    props.setIsExamLaunchedToFalse()
    if (action == "view_appeal") {
      setShowAppeal(true);
    } else {
      setShowAppeal(false);
    }
    setShowAssignment(true);
    setAssessmentId(id);
    setTab("viewAssessment");
    if(!history.location.pathname.includes("shortCourse")){
      history.push(`/course/${props.qid}/subject/${props.subjectId}/assessment/${id}`);      
    } 
  };

  const closeAssignment = () => {
    if (!examLaunched?.isExamLaunched) {
      setShowAssignment(false);
    }
    setTimeout(() => {
      assignmentsLink.current.click();
    }, 0);
  };

  useEffect(()=>{
    if(history.location.pathname === examLaunched?.url && tab!="viewAssessment"){
      dispatch(setIsExamLaunched({...examLaunched, isClicked: false, isExamLaunched:false}))
    }
  },[])

  useEffect(() => {
    if (tab === "assessment_latest") {
      props.setAssessmentActive(true)
    }
    else{
      props.setAssessmentActive(false)
    }
  }, [tab])

  // const clickTab = (value) => {
  const clickTab = (value, c_id=0, m_id=0) => {
    if(history.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched && value !== "viewAssessment"){
      dispatch(setIsExamLaunched({...examLaunched, isClicked: true}))
      props.handleLearningTab(false)
      // Swal.fire({
      //   title: 'Are you sure you want to exit?',
      //   text: "If you leave the tab, it will change to final",
      //   type: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Leave'
      // }).then(function (result) {
      //   if (result.isConfirmed) {
      //     setIsSubmitDocsAss(true);
      //     props.setIsExamLaunchedToFalse()
      //     setIsSubmiting(true)
      //     setTab(value);
      //     if (value != "viewAssessment") {
      //       setShowAssignment(false);
      //     }
      //     if (value == "assembly") {
      //       setshow({ list: true });
      //     }
      //     if (value == "feeds") {
      //       getMessages();
      //     }
      //     setIsSubmiting(false)
      //     // Active tab
      //     // if (value == "feeds") {
      //     //   setFeedActive("active")
      //     //   setOverviewActive("")
      //     //   setSupportTeamActive("")
      //     //   setAssemblyActive("")
      //     //   setAssessmentActive("")
      //     //   setViewAssessmentActive("")
      //     //   setOnlineClassActive("")
      //     //   setViewAssessmentLatestActive("")
      //     // } else if (value == "overview") {
      //     //   setFeedActive("")
      //     //   setOverviewActive("active")
      //     //   setSupportTeamActive("")
      //     //   setAssemblyActive("")
      //     //   setAssessmentActive("")
      //     //   setViewAssessmentActive("")
      //     //   setOnlineClassActive("")
      //     //   setViewAssessmentLatestActive("")
      //     // } else if (value == "supportTeam") {
      //     //   setFeedActive("")
      //     //   setOverviewActive("")
      //     //   setSupportTeamActive("active")
      //     //   setAssemblyActive("")
      //     //   setAssessmentActive("")
      //     //   setViewAssessmentActive("")
      //     //   setOnlineClassActive("")
      //     //   setViewAssessmentLatestActive("")
      //     // } else if (value == "assembly") {
      //     //   setFeedActive("")
      //     //   setOverviewActive("")
      //     //   setSupportTeamActive("")
      //     //   setAssemblyActive("active")
      //     //   setAssessmentActive("")
      //     //   setViewAssessmentActive("")
      //     //   setOnlineClassActive("")
      //     //   setViewAssessmentLatestActive("")
      //     // } else if (value == "assessment") {
      //     //   setFeedActive("")
      //     //   setOverviewActive("")
      //     //   setSupportTeamActive("")
      //     //   setAssemblyActive("")
      //     //   setAssessmentActive("active")
      //     //   setViewAssessmentActive("")
      //     //   setOnlineClassActive("")
      //     //   setViewAssessmentLatestActive("")
      //     // } else if (value == "viewAssessment") {
      //     //   setFeedActive("")
      //     //   setOverviewActive("")
      //     //   setSupportTeamActive("")
      //     //   setAssemblyActive("")
      //     //   setAssessmentActive("")
      //     //   setViewAssessmentActive("active")
      //     //   setOnlineClassActive("")
      //     //   setViewAssessmentLatestActive("")
      //     // } else if (value == "onlineClass") {
      //     //   setFeedActive("")
      //     //   setOverviewActive("")
      //     //   setSupportTeamActive("")
      //     //   setAssemblyActive("")
      //     //   setAssessmentActive("")
      //     //   setViewAssessmentActive("")
      //     //   setOnlineClassActive("active")
      //     //   setViewAssessmentLatestActive("")
      //     // } else if (value == "assessment_latest") {
      //     //   setFeedActive("")
      //     //   setOverviewActive("")
      //     //   setSupportTeamActive("")
      //     //   setAssemblyActive("")
      //     //   setAssessmentActive("")
      //     //   setViewAssessmentActive("")
      //     //   setOnlineClassActive("")
      //     //   setViewAssessmentLatestActive("active")
      //     // }
      //   }
      // })
    } else {
      setTab(value);
      // if(setTab != "assembly"){
      //   props.handleLearningTab(false)
      // }else{
      //   props.handleLearningTab(true)
      // }
      if (value != "viewAssessment") {
        setShowAssignment(false);
        
      }
      if (value == "assembly") {
        setshow({cards: true, list: false, desc: false})   
      }
      if(value !== "assembly"){  
        setshow({ cards: true, list: false, desc: false })      
        props.setLearningTab(false)
      }

      // if(value == "assembly"){
      //   props.setLearningTab(true)
      // }else{
      //   props.setLearningTab(false)
      // }
      if (value == "feeds") {
        getMessages(c_id, m_id);
        setReactUnreadCount(1)
      }
      // Active tab
      // if (value == "overview") {
      //   setFeedActive("active")
      //   setOverviewActive("")
      //   setSupportTeamActive("")
      //   setAssemblyActive("")
      //   setAssessmentActive("")
      //   setViewAssessmentActive("")
      //   setOnlineClassActive("")
      //   setViewAssessmentLatestActive("")
      // } else if (value == "overview") {
      //   setFeedActive("")
      //   setOverviewActive("active")
      //   setSupportTeamActive("")
      //   setAssemblyActive("")
      //   setAssessmentActive("")
      //   setViewAssessmentActive("")
      //   setOnlineClassActive("")
      //   setViewAssessmentLatestActive("")
      // } else if (value == "supportTeam") {
      //   setFeedActive("")
      //   setOverviewActive("")
      //   setSupportTeamActive("active")
      //   setAssemblyActive("")
      //   setAssessmentActive("")
      //   setViewAssessmentActive("")
      //   setOnlineClassActive("")
      //   setViewAssessmentLatestActive("")
      // } else if (value == "assembly") {
      //   setFeedActive("")
      //   setOverviewActive("")
      //   setSupportTeamActive("")
      //   setAssemblyActive("active")
      //   setAssessmentActive("")
      //   setViewAssessmentActive("")
      //   setOnlineClassActive("")
      //   setViewAssessmentLatestActive("")
      // } else if (value == "assessment") {
      //   setFeedActive("")
      //   setOverviewActive("")
      //   setSupportTeamActive("")
      //   setAssemblyActive("")
      //   setAssessmentActive("active")
      //   setViewAssessmentActive("")
      //   setOnlineClassActive("")
      //   setViewAssessmentLatestActive("")
      // } else if (value == "viewAssessment") {
      //   setFeedActive("")
      //   setOverviewActive("")
      //   setSupportTeamActive("")
      //   setAssemblyActive("")
      //   setAssessmentActive("")
      //   setViewAssessmentActive("active")
      //   setOnlineClassActive("")
      //   setViewAssessmentLatestActive("")
      // } else if (value == "onlineClass") {
      //   setFeedActive("")
      //   setOverviewActive("")
      //   setSupportTeamActive("")
      //   setAssemblyActive("")
      //   setAssessmentActive("")
      //   setViewAssessmentActive("")
      //   setOnlineClassActive("active")
      //   setViewAssessmentLatestActive("")
      // } else if (value == "assessment_latest") {
      //   setFeedActive("")
      //   setOverviewActive("")
      //   setSupportTeamActive("")
      //   setAssemblyActive("")
      //   setAssessmentActive("")
      //   setViewAssessmentActive("")
      //   setOnlineClassActive("")
      //   setViewAssessmentLatestActive("active")
      // }
    }


    
    // if(value !== "viewAssessment" && !history.location.pathname.includes("shortCourse") && !examLaunched?.isExamLaunched){
    if(value != "viewAssessment" && !history.location.pathname.includes("shortCourse") && props.qid && !examLaunched?.isExamLaunched && props.isAdmin !== 1){
      history.push(`/course/${props.qid}/subject/${props.subjectId}`);
    }
    setTab(value)
  };

  useEffect(() => {
    let start = document.getElementById("howToJoinFunction");
    if (start) {
      start.addEventListener("click", async function () {
        let video = start.getAttribute("data-id");
        let type = start.getAttribute("data-type");
        let link = `https://player.vimeo.com/video/${video}`;
        if (type == 'youtube') {
          link = `https://www.youtube.com/watch?v=${video}`
        }
        playerModelBox(link)
      });
    }
  });

  const closeModal = () => {
    setModalOpen(false);
    setVideoId('')
  };

  const playerModelBox = (links) => {
    setModalOpen(true);
    setVideoId(links)
  }

  const handleScroll = (scrollValues, prevScrollValues = {}) => {
    if ((page > 0) && (page < lastPage)) {
      if (scrollbarsRef?.current?.scrollTop < 1) {
        setSkeletonLoader(true)
        if (page < lastPage) {
          const payload = { 
            room_id: props.subjectContent.subject.id, 
            user_id: user.id, 
            search:feedSearch,
            classFilter,
            replyFilters,
            mentionFilters,
            unReadMessages,
            assessmentFilter,
            notificationFilter
          }
          GetPostFeedMessagesPaginate(payload, page + 1).then(data => {
            setLastPage(data.data.lastPage)
            setPage(data.data.page)
            setFeeds((prev) => [...data.data.data, ...prev]);
            setTimeout(() => {
              let height = scrollbarsRef?.current?.scrollHeight - lastY
              scrollbarsRef.current.scrollTo(0, height);
              setLastY(scrollbarsRef?.current?.scrollHeight);
            }, 1000);
            setLoader(false);
            setSkeletonLoader(false)
          })
            .catch(err => { console.log(err) })
        }
      } else {
        if (skeletonLoader) setSkeletonLoader(false)
      }
      setLoader(false)
    }
  }

  const openReplyHandle = (openId) => {
    setOpenReplyIds(openId)
    setShowNewStatus(false)
    setEditableArr([])
    setEditablePost('')
  }

  const editIdHandle = (editId) => {
    setEditableArr(editId)
    setShowNewStatus(false)
    setOpenReplyIds('')
    setEditablePost('')
  }

  const editIdPostHandle = (editId) => {
    setEditablePost(editId)
    setEditableArr([])
    setShowNewStatus(false)
    setOpenReplyIds('')
  }

  const newDiscussHandle = (newStatus) => {
    setShowNewStatus(newStatus)
    setOpenReplyIds('')
    setEditableArr([])
    setEditablePost('')
  }

  const resetEditor = () => {
    setShowNewStatus(false)
    setOpenReplyIds('')
    setEditableArr([])
    setEditablePost('')
  }

  const deleteHandle = (data) => {
    data.user_id = user.id;
    data.role = 'student'
    subscription.emit("messageDelete", data);
  };

  const likeDislikeHandle = (data) => {
    data.user_id = user.id;
    data.role = 'student'
    subscription.emit("likeDislike", data);
  };

  const updatePostImportant = (postData, value) => {
    setFeeds((pre) => {
      // console.log('the previous value is ', pre)
      const targetMessage = pre.findIndex(item => item.id === postData.id);
      pre[targetMessage].important = value ? 1 : 0
      return [...pre]
    });
  }

  const sendPost = (messageValue, allData_id, attachments, sizes, subject) => {
    let tempImportant = false
    let tempData = feeds
    let deletedFiles = []
    let remainFiles = []
    feeds.map(i => {
      if (i.id == allData_id) {
        i.attachments.map(j => {
          if (j.isDeleted) {
            deletedFiles.push(j.attachment)
          }
          else {
            remainFiles.push(j)
          }
        })
        tempImportant = i.important
        attachments = [...remainFiles.map(x => x.attachment), ...attachments]
        deletedFiles = deletedFiles
        sizes = [...remainFiles.map(x => x.size ? x.size : 'NA'), ...sizes]
        // console.log('attachments and sizes arr is ', attachments, sizes, deletedFiles, remainFiles)
      }
    })

    // console.log("messageVal -=-=-=-> ", messageValue);
    // return;
    subscription.emit("message", {
      message: messageValue,
      subject: subject,
      feed: allData_id,
      comment_id: allData_id,
      message_id: allData_id,
      type: "message",
      message_type: 'edited',
      files: attachments,
      sizes: sizes,
      taggedUsers: taggedUsers ? taggedUsers : [],
      important: tempImportant,
      deletedFiles: deletedFiles,
      user_id: user.id,
      role: 'student'
    });
    resetEditor()
  };

  const deletedFileHandle = (id, path) => {
    let tempData = feeds
    tempData.map(i => {
      if (i.id == id) {
        i.attachments.map(j => {
          if (path != j.attachment) {
            if (!j.isDeleted) {
              j.isDeleted = false
            }
          }
          else {
            j.isDeleted = true
          }
        })
      }
    })
  }

  const updateReadMessages = (msg_id, cmt_id) => {
    let tempFeed = feeds
    tempFeed.map(i => {
      if (i.id == msg_id) {
        if (i.readMessages && i.readMessages.id) {
          i.readMessages.message_id = msg_id
          i.readMessages.comment_id = cmt_id
        }
        else {
          i.readMessages = { message_id: msg_id, comment_id: cmt_id }
        }
      }
    })
    setFeeds(tempFeed)
  }


  useEffect(()=>{
    if(chatUnreadCount>0 && tab == 'feeds'){
      readAllCommentMessages({sid:props.subjectId, user_id:user.id}).then((res)=>{
        if(res.status===200){
          setChatUnreadCount(0)
          if(!history.location.pathname.includes("shortCourse")){
            props.updateSubjectOnly();
          }
          GetMyUnreadChatNotificationCount().then((response) =>{
            dispatch(
              setUnreadChatNotificationCount(
                response?.data?.chatRoomUnreadMessageCount?.chatRoomUnreadMessageCount
                  ? response.data.chatRoomUnreadMessageCount.chatRoomUnreadMessageCount
                  : 0
              )
            );
          })          
          // dispatch(setUnreadChatNotificationCount(0))
        }
      }).catch((err)=>{console.log(err)})
    }
  },[chatUnreadCount,reactUnreadCount])

  // const fxRenderAfterCourseUpdate = () => {
  //   console.log("here")
  //   setReRender(9999);
  //   props.updateSubject()
  // }

  let showShortCourse = props.subjectContent?.subject?.subject_type === "shortcourse" && props.subjectContent?.subject?.type === "selfplaced"  ? (props.subjectContent?.subject?.subjectStatus !== "Pending Review" && props.subjectContent?.subject?.subjectStatus !== "Not Started") : true;

  // useEffect(()=>{
  //   if(password!=ADMIN_PASSWORD){
  //     GetCourseStatistics({sid : props.subjectContent.subject.id}).then((res)=>{
  //       if(res.status==200){
  //         setStatistics(res.data);
  //       }else{
  //         setStatistics(null)
  //       }
  //     }).catch((err)=>{
  //       console.log(err)
  //     })
  //   }else{
  //     GetCourseResourceDocumentsCount({sid : props.subjectContent.subject.id}).then((res)=>{
  //       if(res.status==200){
  //         setStatistics(res.data);
  //       }else{
  //         setStatistics(null)
  //       }
  //     })
  //   }
  // },[props.subjectContent.subject.id])

  return (
    <>
      {modalOpen &&<div className="modal-update">
        <ReactDynamicModal
          data-backdrop="static"
          initWidth={640}
          initHeight={410}
          onRequestClose={closeModal}
          isOpen={modalOpen}
          data={
            <div className="body">
              <ReactPlayer url={videoId} controls={true} className="react-player" width="100%" height="100%" pip={true} stopOnUnmount={false} youtube="youtube" />
            </div>
          }
        />
      </div> }     
      {loading && <SkeletonSubjectHeadBox />}
      {!loading && props.subjectContent.subject ? <SubjectHeadBox isAdmin={props.isAdmin} subjectContent={props.subjectContent} content={props.subjectContent.subject} updateSubject={props.updateSubject} statisticsData={statistics} /> : null}      
      {/* <div className={`icon ${row.program_type_name ? row.program_type_name?.toString()?.replace("’s", "")?.replace(" ", "") : "null"}-icon`} title={row.program_type_name ? row.program_type_name : "NA"}><ProgramIcons degree={row.program_type_name ? row.program_type_name : "NA"}/></div> */}
      <UpdateMentoredSubject 
        row={props.subjectContent.subject} 
        updateSubject={() => props.updateSubject(props.subjectContent?.subject?.subject_type === "shortcourse")} 
        shortCourse={props.subjectContent?.subject?.subject_type === "shortcourse"} 
      />
      <CompleteMentoredSubject
        row={props.subjectContent.subject}
        updateSubject={() => props.updateSubject(props.subjectContent?.subject?.subject_type === "shortcourse")}
      />
      <MentoredSubjectNotes subject_id={props.subjectContent.subject.id} />
      <div className="lecture-detail-bottom">
        <div className="lecture-tabs-wrap">
          <ul className="nav nav-pills course-nav-sticky" id="pills-tab" role="tablist">
            {(props.subjectContent.subject.convChatGroup == 1 && password!=ADMIN_PASSWORD && props.subjectContent?.subject?.subjectStatus !== "Not Started") ? (
              <li className={`nav-item ${chatUnreadCount > 0 && 'padding-left-15px'} `} onClick={() => clickTab("feeds")}>
                <span
                  // id="pills-Posts-tab"
                  // data-toggle="pill"
                  // href="#pills-Posts"
                  // role="tab"
                  // aria-controls="pills-Posts"
                  // aria-selected="true"
                  className={`nav-link ${(tab == "feeds" || tab == "shared_files") ? "active" : ""}`}
                >
                  <i className="far fa-comments"></i>News Room
                  {chatUnreadCount > 0 ? <small className="notify-count">{chatUnreadCount > 9 ? "9+" : chatUnreadCount}</small> : null}
                </span>
              </li>
            ) : null}

           

            <li className="nav-item" title="Overview" onClick={() => clickTab("overview")}>
              <span
                className={`nav-link ${tab == "overview" ? "active" : ""}`}
                // className={props.subjectContent.subject.convChatGroup == 1 ? "nav-link" : "nav-link active"}
                // id="pills-Overview-tab"
                // data-toggle="pill"
                // href="#pills-Overview"
                // role="tab"
                // aria-controls="pillsOverview"
                // aria-selected="false"
              >
                <i className="fal fa-id-card"></i>
                Overview
              </span>
            </li>

            {(showShortCourse && props.subjectContent.subject?.subjectStatus !== "Not Started") ?
              <>
                {/* <li className="nav-item" title="Support Team" onClick={() => clickTab("supportTeam")}>
                  <span
                    className={`nav-link ${tab == "supportTeam" ? "active" : ""}`}
                  // id="pills-Support-tab"
                  // data-toggle="pill"
                  // href="#pills-Support"
                  // role="tab"
                  // aria-controls="pills-Support"
                  // aria-selected="false"
                  >
                    <i className="fal fa-users"></i>Support Team
                  </span>
                </li> */}
                {(props.subjectContent?.subject?.hide_learning_material == 0 && props.subjectContent.subject?.subjectStatus !== "Not Started") ? <li className="nav-item" title="Learning Material" onClick={() => clickTab("assembly")}>
                  <span
                    className={`nav-link ${tab == "assembly" ? "active" : ""}`}
                  // id="pills-Assemblies-tab"
                  // data-toggle="pill"
                  // href="#pills-Assemblies"
                  // role="tab"
                  // aria-controls="pills-Assemblies"
                  // aria-selected="false"
                  >
                    <i className="fal fa-books"></i>Learning Material
                  </span>
                </li> : null}
              </> : null}
            {/* {props.isAdmin != 1 ? (
              <li
                className="nav-item iteam"
                onClick={() => clickTab("assessment")}
                title="Your Assessments"
              >
                <a
                  className={`nav-link ${assessmentActive}`}
                  id="pills-Assignments-tab"
                  data-toggle="pill"
                  href="#pills-Assignments"
                  role="tab"
                  aria-controls="pills-Assignments"
                  aria-selected="false"
                  ref={assignmentsLink}
                >
                  <i className="fal fa-ballot-check"></i>Your Assessments
                </a>
              </li>) : null} */}
              
            {(props.isAdmin != 1 && showShortCourse && props.subjectContent?.subject?.hide_assessments ==0 && props.subjectContent.subject?.subjectStatus !== "Not Started")  ? (
              <li className="nav-item iteam" onClick={() => clickTab("assessment_latest")} title="Your Assessments">
                <span
                  className={`nav-link ${tab == "assessment_latest" ? "active" : ""}`}
                  // id="pills-Assignments-tab"
                  // data-toggle="pill"
                  // href="#pills-Assignments-latest"
                  // role="tab"
                  // aria-controls="pills-Assignments"
                  // aria-selected="false"
                  ref={assignmentsLink}
                >
                  <i className="fal fa-ballot-check"></i>Your Assessments
                </span>
              </li>
            ) : null}
            {showAssignment && showShortCourse && tab == "viewAssessment"  ? (
              <li className="nav-item" onClick={() => clickTab("viewAssessment")} title="View Assessment/Appeal">
                <span
                  className={`nav-link ${tab == "viewAssessment" ? "active" : ""}`}
                  // id="pills-Assignment-tab"
                  // data-toggle="pill"
                  // href="#pills-Assignment"
                  // role="tab"
                  // aria-controls="pills-Assignment"
                  // aria-selected="false"
                  ref={assignmentLink}
                >
                  <i className="fal fa-ballot-check"></i>Assessment
                </span>
              </li>
            ) : null}
            {(props.isAdmin != 1 && props.subjectContent?.subject?.type !== "selfplaced" && props.subjectContent.subject?.subjectStatus !== "Not Started") ? (
              <li className="nav-item" title="Class Schedule" onClick={() => clickTab("onlineClass")}>
                <span
                  // id="pills-Classes-tab"
                  // data-toggle="pill"
                  // href="#pills-Classes"
                  // role="tab"
                  // aria-controls="pills-Classes"
                  // aria-selected="true"
                  className={`nav-link ${tab == "onlineClass" ? "active" : ""}`}
                >
                  <i className="fal fa-laptop"></i>Class Schedule
                </span>
              </li>
            ) : null}
          
          {props.isAdmin !== 1 && <li className="course-progress-tab">
            <ul className="course-progress">
              {props.subjectContent?.subject?.hide_learning_material_count == 0 ? <>
                <li title="Learning Material"><i className="fal fa-books"></i></li>
                <li>
                  <div className="progress">
                    <div
                      className={"progress-bar-text progress-bar-striped progress-bar-animated" + (statistics?.totalLearningMaterialPercentage<=0 ? " zero-progress" : "")}
                      role="progressbar"
                      style={{
                        width: (statistics?.totalLearningMaterialPercentage ? statistics?.totalLearningMaterialPercentage  : 15) + "%",
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {statistics?.totalLearningMaterialPercentage ? statistics?.totalLearningMaterialPercentage  : "0"}%
                    </div>
                  </div>
                </li>
              </>
              :null}
              {props.subjectContent?.subject?.hide_assessments_count == 0 ? 
              <>
                <li title="Assessments"><i className="fal fa-ballot-check"></i></li>
                <li>
                  <div className="progress">
                    <div
                      className={"progress-bar-text progress-bar-striped progress-bar-animated" + (statistics?.totalAssessmentPercentage<=0 ? " zero-progress" : "")}
                      role="progressbar"
                      style={{
                        width: (statistics?.totalAssessmentPercentage ? statistics?.totalAssessmentPercentage : 15) + "%",
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {statistics?.totalAssessmentPercentage ? statistics?.totalAssessmentPercentage : 0}%
                    </div>
                  </div>
                </li>
              </>
              :null}
              {
                (props.subjectContent?.subject?.hide_learning_material_count === 0 &&
                  props.subjectContent?.subject?.hide_assessments_count === 0)
                  ?              <>
                <li title="Complete Progress"><i className="fal fa-trophy"></i></li>
                <li>
                  <div className="progress">
                    <div
                      className={"progress-bar-text progress-bar-striped progress-bar-animated" + (statistics?.progressBar<=0 ? " zero-progress" : "")}
                      role="progressbar"
                      style={{
                        width: (statistics?.progressBar ? statistics?.progressBar : 15) + "%",
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {statistics?.progressBar ? statistics?.progressBar : 0}%
                    </div>
                  </div>
                </li>
              </>
              :null}
            </ul>
          </li>}
          </ul>
          <div className="tab-content" id="pills-tabContent">
            {tab == "feeds" && (
              <>
                <div className="tab-pane fade show active" id="pills-Posts" role="tabpanel" aria-labelledby="pills-Posts-tab">
                  <div className="ps-fd-card">                    
                    <FeedMessageForm
                      pSendMessage={sendMessage}
                      TypingMessage={TypingMessage}
                      isTyping={typing}
                      taggingUsers={props.subjectContent.listUsers}
                      setShowNewStatus={newDiscussHandle}
                      showNewStatus={showNewStatus}
                    />

                    <div
                      className="scroll-sec-feed-msg"
                      ref={scrollbarsRef}
                      onScroll={debounce(() => {
                        handleScroll();
                      }, 1000)}
                    >
                      <FeedMessages
                        feeds={feeds}
                        websocket={SocketConnection}
                        online={users}
                        loadMore={skeletonLoader || loader}
                        taggingUsers={props.subjectContent.listUsers}
                        updateMsg={getMessages}
                        setOpenReplyId={openReplyHandle}
                        openReplyId={openReplyId}
                        setShowHideEdit={editIdHandle}
                        showHideEditArr={editableArr}
                        setShowHidePostEdit={editIdPostHandle}
                        showHidePostEdit={editablePost}
                        resetEditor={resetEditor}
                        deleteHandle={deleteHandle}
                        likeDislikeHandle={likeDislikeHandle}
                        updatePostImportant={updatePostImportant}
                        sendClick={sendPost}
                        deletedFile={deletedFileHandle}
                        reduceCount={reduceCount}
                        updateReadMessages={updateReadMessages}
                        subjectId={props.subjectId}
                        showIcon={showBlankIcon}
                        chatUnreadCount={chatUnreadCount}
                        setChatUnreadCount={setChatUnreadCount}
                        updateSubject={props.updateSubject}
                      />
                      <div id="content" />
                    </div>
                    <FeedToolBar 
                      subjectContent={props.subjectContent}
                      feedSearch={feedSearch}
                      setFeedSearch={setFeedSearch}
                      setTab={()=>{clickTab("shared_files")}} 
                      setClassFilter={setClassFilter}
                      setReplyFilter={setReplyFilter}
                      setMentionFilter={setMentionFilter}
                      setUnReadFilter={setUnReadFilter}
                      setAssessmentFilter={setAssessmentFilter}
                      setNotificationFilter={setNotificationFilter}
                      showMessage={getMessages}
                    />
                  </div>
                </div>
              </>
            )}
            {tab == "shared_files" && (
              <SharedFileList subject={props.subjectContent.subject.id} setTab={(c_id, m_id)=>{clickTab("feeds", c_id, m_id)}} />
            )}
            {tab == "overview" && !loading && props.subjectContent.subject ? (
              <div role="tabpanel" aria-labelledby="pills-Overview-tab">
                <div className="Actabs">                  
                  <div> 
                   {ReactHtmlParser(props.subjectContent.subject.welcome)}
                  </div>
                  <TeamBox subjectId={props.subjectId}  subject__welcome={props.subjectContent?.subject?.welcome}/>
                </div>
              </div>
            ) : null}
            {tab == "supportTeam" ? (
              <div className="tab-pane fade show active" id="pills-Support" role="tabpanel" aria-labelledby="pills-Support-tab">
                <TeamBox subjectId={props.subjectId} />
              </div>
            ) : null}
            {tab == "assembly" ? (
              <div className="tab-pane fade show active" id="pills-Assemblies" role="tabpanel" aria-labelledby="pills-Assemblies-tab">
                <div className="learning-quiz-wrap">
                  {show && show.list ? (
                    <SubjectAssemblies
                      setUpdateLearningMaterialData={value=>setUpdateLearningMaterialData(value)}
                      assemblyData={props.assemblyCategoryList }
                      assembliesItems={props.assembliesItems}
                      setassembliesItems={props.setassembliesItems}
                      subassembliesItems={props.subassembliesItems}
                      setSubassembliesItems={props.setSubassembliesItems}
                      subjectId={props.subjectId}
                      setQuizId={setQuizId}
                      setshow={setshow}
                      show={show}
                      QuizId={QuizId}
                      setrId={setrId}
                      rId={rId}
                      isAdmin={props.isAdmin}
                      getSubContentMethod={props.getSubContentMethod}
                      assemblies={props.assemblies}
                      moduleId={props.moduleId}
                      setModuleId={props.setModuleId}
                      lessonList={props.lessonList}
                    />
                  ) : null}
                  {show && show.cards ? <AssemblyItemBox setLessonList={props.setLessonList} loading={assembliesDataLoad} assemblies={assembliesList} setshow={setshow} setModuleId={props.setModuleId} setLearningTab={props.setLearningTab} setAssemblyCategoryList={props.setAssemblyCategoryList }/> : null}
                  {/* {show.attempt && <ViewQuiz quizId={QuizId} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} />} */}
                  {/* {show.desc && <QuizDescription quizId={QuizId} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} />} */}
                  {/* {show.question && <ViewQuestion quizId={QuizId} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} isSubmiting={isSubmiting} />}
                    {show.review && <ReviewQuiz quizId={QuizId} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} />}  */}
                  {/* <ViewQuiz /> */}
                  {/* <QuizDescription /> */}
                  {/* <ViewQuestion /> */}
                  {/* <SubmitConfirmation /> */}
                  {/* <ReviewQuiz /> */}
                </div>
              </div>
            ) : null}
            <div
              className="tab-pane fade"
              id="pills-Assignments"
              role="tabpanel"
              aria-labelledby="pills-Assignments-tab"
            >
              {tab == "assessment" ? (
                <SubjectAssignmentsLatest
                  subjectId={props.subjectId}
                  parentCall={viewAssignment}
                ></SubjectAssignmentsLatest>
              ) : null}
            </div>

            <div className="tab-pane fade show active" id="pills-Assignments-latest" role="tabpanel" aria-labelledby="pills-Assignments-tab-latest">
              {tab == "assessment_latest" ? <SubjectAssignmentsLatest subjectId={props.subjectId} parentCall={(id, action) => {viewAssignment(id, action)}} shortCourse={props.subjectContent?.subject?.subject_type === "shortcourse" &&  props.subjectContent?.subject?.type === "selfplaced"} subjectContent={props.subjectContent?.subject} ></SubjectAssignmentsLatest> : null}
            </div>
            <div className="tab-pane fade show active" id="pills-Assignment" role="tabpanel" aria-labelledby="pills-Assignment-tab">
              {showAppeal == true && tab == "viewAssessment" && <Appeal parentCall={closeAssignment} assessmentId={assessmentId} viewAssignmentCall={(id, action)=>{viewAssignment(id, action)}}></Appeal>}
              {showAppeal == false && tab == "viewAssessment" && (
                <Assignment
                  getSubContentMethod={props.getSubContentMethod}
                  parentCall={closeAssignment}
                  openAppealCall={(id, action) => {viewAssignment(id, action)}}
                  assessmentId={assessmentId}
                  setQuizId={setQuizId}
                  setshow={setshow}
                  setrId={setrId}
                  rId={rId}
                  desc={show.desc}
                  attemp={show.attempt}
                  ques={show.question}
                  review={show.review}
                  setIsExamLaunchedToTrue={props.setIsExamLaunchedToTrue}
                  setIsExamLaunchedToFalse={props.setIsExamLaunchedToFalse}
                  isExamLaunched={props.isExamLaunched}
                  setTab={setTab}
                  isSubmiting={isSubmiting}
                  courseId={props.qid}
                  subjectId={props.subjectId}
                  setShowAssignment={setShowAssignment}
                  isSubmitDocsAss={isSubmitDocsAss}
                  setIsSubmitDocsAss={(status)=>setIsSubmitDocsAss(status)}
                  gradingStandard={props.subjectContent?.subject?.grading_standard}
                ></Assignment>
              )}
            </div>
            <div className="tab-pane fade show active " id="pills-Classes" role="tabpanel" aria-labelledby="pills-Classes-tab">
              {/* {tab === "onlineClass" ? <OnlineClasses subjectId={props.subjectId}></OnlineClasses> : null} */}
              {tab === "onlineClass" ? <GlobalCalendar subjectId={[props.subjectId]} givenFilter="all" showFilter={false}></GlobalCalendar> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
// }
};

/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (payload) => dispatch(modalOpen(payload)),
  };
}

// export default React.memo(SubjectContent);
export default connect(mapDispatchToProps)(React.memo(SubjectContent));

