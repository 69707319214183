import React, { useState, useEffect, useCallback, memo } from "react";
import { fileTrackingService } from "../../services/AssembliesService";
import AssembliesItems from "./AssembliesItems";
import { useDispatch, useSelector } from "react-redux";
import { UpdateLessonType, updateActivityBtnAction, updateCurrentActivity, updateSubjectContentLoadingOnLesson } from "../../store/actions";
import ScrollBar from "react-perfect-scrollbar";

const SubjectAssemblies = ({
  setQuizId,
  setshow,
  QuizId,
  setrId,
  rId,
  moduleId,
  show,
  getSubContentMethod,
  subassembliesItems,
  assemblyData,
  setModuleId,
  lessonList = [],
  setUpdateLearningMaterialData,
  subjectId,
  isAdmin,
}) => {
  // State management
  const [loading, setLoading] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [buttonCheckedStatus, setButtonCheckedStatus] = useState(0);
  const [subCategoryAcc, setSubCategoryAcc] = useState({ state: null, id: null });
  const [documentId, setDocumentId] = useState([]);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [currentCategoryData, setCurrentCategoryData] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const currentActivity = useSelector(state => state.learningMaterial.current_activity);
 
  useEffect(()=>{
    dispatch(updateSubjectContentLoadingOnLesson("no loading"));
  },[])

  // Handlers
  const handleFileTracking = useCallback((item) => {
    const trackFile = async () => {
      try {
        const payload={
          itemId: item?.isMultiFormat ? item?.assembly_item_id : item?.item_id
        }
        if(item?.isMultiFormat){
          payload.document_id=item?.id
        }
        await fileTrackingService(payload);
        
        setButtonCheckedStatus(true);
        setDocumentId(prev => [...prev, item?.isMultiFormat ? item.assembly_item_id : item?.item_id]);
        setUpdateLearningMaterialData({ 
          state: true, 
          id: item?.assembly_id 
        });
      } catch (error) {
        console.error('File tracking error:', error);
      }
    };

    trackFile();
  }, [setUpdateLearningMaterialData]);

  const updateNextActivity = useCallback((nextActivity) => {
    if (!nextActivity) return;

    // Always update the current activity and lesson type
    dispatch(updateCurrentActivity(nextActivity?.item_id));
    dispatch(UpdateLessonType(nextActivity?.item_type));

    // Handle sub-category state
    if (nextActivity?.sub_children?.length) {
      setSubCategoryAcc({ state: true, id: nextActivity?.item_id });
      // If it has sub-children, set the first child as current
      const firstChild = nextActivity?.sub_children[0];
      dispatch(updateCurrentActivity(firstChild?.item_id));
      dispatch(UpdateLessonType(firstChild?.item_type));
    } else {
      setSubCategoryAcc({ state: false, id: null });
    }
  }, [dispatch]);

  const findNextAssemblyLesson = useCallback(() => {
    const { assembliesItems = [] } = lessonList;
    
    for (let i = 0; i < assembliesItems?.length; i++) {
      const lessons = assembliesItems[i].lessons || [];
      let currentFound = false;
      
      for (let j = 0; j < lessons.length; j++) {
        const lesson = lessons[j];
        
        // Check if current activity is in sub_children
        if (lesson.sub_children?.some(sub => sub.item_id === currentActivity)) {
          currentFound = true;
          
          // Check remaining items in current sub_children
          const subIndex = lesson.sub_children.findIndex(sub => sub.item_id === currentActivity);
          if (subIndex < lesson.sub_children.length - 1) {
            return lesson.sub_children[subIndex + 1];
          }
          let x = 1
          // Move to next lesson
          if (j < lessons.length - 1) {
            while(!lessons[j + x]?.sub_children.length && lessons[j + x]?.item_type == 3){
              x++
              if(lessons[j + x]?.sub_children.length){
                break;
              }
            }
            if(j + x < lessons.length){  
              return lessons[j + x];
            }
          }
          break;
        }
        
        // Check if current activity is the lesson itself
        if (lesson.item_id === currentActivity) {
          currentFound = true;
          
          // If lesson has sub_children, return first sub-child
          if (lesson.sub_children?.length) {
            return lesson.sub_children[0];
          }
          
          // Move to next lesson
          let x = 1
          if (j < lessons.length - 1) {
            while(!lessons[j + x]?.sub_children.length && lessons[j + x]?.item_type == 3){
              x++
              if(lessons[j + x]?.sub_children.length){
                break;
              }
            }
            if(j + x < lessons.length){  
              return lessons[j + x];
            }
          }
          break;
        }
      }
      
      // If current activity was found in this assembly, check next assembly
      if (currentFound && i < assembliesItems.length - 1) {
        let nextAssembly = null;
      
        // Looping through subsequent assemblies to find one with lessons
        for (let j = i + 1; j < assembliesItems.length; j++) {
          if (assembliesItems[j]?.lessons?.length) {
            nextAssembly = assembliesItems[j];
            break;  // Exit the loop once a next assembly with lessons is found
          }
        }
        let x = 0
        // If a next assembly with lessons is found, set data
        if (nextAssembly) {
          while(!nextAssembly?.lessons[x].sub_children.length && nextAssembly?.lessons[x].item_type == 3){
            x++
            if(nextAssembly?.lessons[x].sub_children.length){
              break;
            }
          }
          setCurrentCategoryData(nextAssembly.lessons);
          setModuleId(nextAssembly.lessons[x]?.parent_id);
          return nextAssembly.lessons[x];
        }
      }
    }
    
    return null;
  }, [lessonList, currentActivity, setModuleId]);

  const handleNextActivity = useCallback(() => {
    dispatch(updateActivityBtnAction('continue'))
    const nextLesson = findNextAssemblyLesson();
    if (nextLesson) {
      updateNextActivity(nextLesson);
    }
  }, [findNextAssemblyLesson, updateNextActivity]);
  
  // back button
  const findPrevAssemblyLesson = () => {
    const { assembliesItems = [] } = lessonList;

    for (let i = assembliesItems.length - 1; i >= 0; i--) {
      const lessons = assembliesItems[i].lessons || [];
      let currentFound = false;

      for (let j = lessons.length - 1; j >= 0; j--) {
        const lesson = lessons[j];

        // Check if current activity is in sub_children
        if (lesson.sub_children?.length) {
          const subIndex = lesson.sub_children.findIndex(sub => sub.item_id === currentActivity);

          if (subIndex !== -1) {
            currentFound = true;
            // If not the first sub-child, return previous sub-child
            if (subIndex > 0) {
              return lesson.sub_children[subIndex - 1];
            } else {
              // If first sub-child, return the parent lesson
              let x = 0
              for (let k = j - 1; k >= 0; k--) {
                while(!lessons[k-x]?.sub_children.length && lessons[k-x]?.item_type == 3){
                  x++
                  if(lessons[k-x]?.sub_children.length){
                    break;
                  }
                }
                const prevLesson = lessons[k-x];
                if(prevLesson){
                  if (prevLesson.sub_children?.length > 0) {
                    return prevLesson.sub_children[prevLesson.sub_children.length - 1];
                  }
                  return prevLesson;
                }
                
              }
            }
          }
        }

        // Check if current activity is the lesson itself
        if (lesson.item_id === currentActivity) {
          currentFound = true;

          // If there's a previous lesson in current assembly
          if (j > 0) {
            const prevLesson = lessons[j - 1];
  
            // If previous lesson has sub_children, return its last sub-child
            if (prevLesson.sub_children?.length) {
              return prevLesson.sub_children[prevLesson.sub_children.length - 1];
            }
            let x = 1
            if (j-x < lessons.length - 1) {
              while(!lessons[j - x]?.sub_children.length && lessons[j - x]?.item_type == 3){
                x++
                if(lessons[j - x]?.sub_children.length){
                  break;
                }
              }
              if((j - x) >= 0){  
                return lessons[j - x];
              }
            }
            // Otherwise, return the previous lesson itself
            // return prevLesson;
          }else {
            // If at the first lesson in the group, move to previous assembliesItems
            for (let k = i - 1; k >= 0; k--) {
              const prevLessons = lessons[k] || [];
              if (prevLessons.length) {
                const lastLesson = prevLessons[prevLessons.length - 1];
  
                // If the last lesson has sub-children, return the last sub-child
                if (lastLesson.sub_children?.length) {
                  return lastLesson.sub_children[lastLesson.sub_children.length - 1];
                }
                // Otherwise, return the last lesson itself
                return lastLesson;
              }
              break;
            }
          }
        }
      }

      // If current activity was found in this assembly, check previous assembly
      if (currentFound && i > 0) {
        for (let k = i - 1; k >= 0; k--) {
          const prevAssembly = assembliesItems[k];
          // Check if the previous assembly has lessons
          if (prevAssembly?.lessons?.length) {
            const lessons = prevAssembly.lessons;
      
            // Update the current category and module ID
            setCurrentCategoryData(lessons);
            setModuleId(lessons[lessons.length - 1]?.parent_id);
      
            // Check if the last lesson has sub_children
            const lastLesson = lessons[lessons.length - 1];
            if (lastLesson.sub_children?.length) {
              // Return the last sub-child if present
              return lastLesson.sub_children[lastLesson.sub_children.length - 1];
            }
      
            // Otherwise, return the last lesson itself
            return lastLesson;
          }
        }
      }
    }

    return null;
  }
  // const findPrevAssemblyLesson = () => {
//     const { assembliesItems = [] } = lessonList;
//     console.log("prevAssemblyLesson", assembliesItems);

//     for (let i = assembliesItems?.length - 1; i >= 0; i--) {
//       const lessons = assembliesItems[i].lessons || [];
//       let currentFound = false;
      
//       for (let j = lessons.length - 1; j >= 0; j--) {
//         const lesson = lessons[j];
        
//         // Check if current activity is in sub_children
//         if (lesson.sub_children?.some(sub => sub.item_id === currentActivity)) {
//           currentFound = true;
          
//           // Check previous items in current sub_children
//           const subIndex = lesson.sub_children.findIndex(sub => sub.item_id === currentActivity);
//           if (subIndex > 0) {
//             return lesson.sub_children[subIndex - 1];
//           }
          
//           console.log("prev child 223", j);
//           let x = 1;
//           // Move to previous lesson
//           if (j > 0) {
//             while (!lessons[j - x]?.sub_children.length && lessons[j - x]?.item_type == 3) {
//               x++;
//               if (lessons[j - x]?.sub_children.length) {
//                 break;
//               }
//             }
//             console.log("prev child", lessons, j, x);
//             if (j - x >= 0) {
//               return lessons[j - x];
//             }
//           }
//           break;
//         }
        
//         // Check if current activity is the lesson itself
//         if (lesson.item_id === currentActivity) {
//           currentFound = true;
          
//           // If lesson has sub_children, return last sub-child
//           if (lesson.sub_children?.length) {
//             return lesson.sub_children[lesson.sub_children.length - 1];
//           }
          
//           // Move to previous lesson
//           console.log("prev child 222", j);
//           let x = 1;
//           if (j > 0) {
//             while (!lessons[j - x]?.sub_children.length && lessons[j - x]?.item_type == 3) {
//               x++;
//               if (lessons[j - x]?.sub_children.length) {
//                 break;
//               }
//             }
//             console.log("prev child", lessons, j, x);
//             if (j - x >= 0) {
//               console.log("lessons[j - x]",lessons[j - x])
//               return lessons[j - x];
//             }
//           }
//           break;
//         }
//       }
      
//       // If current activity was found in this assembly, check previous assembly
//       if (currentFound && i > 0) {
//         let prevAssembly = null;
      
//         // Looping through previous assemblies to find one with lessons
//         for (let j = i - 1; j >= 0; j--) {
//           if (assembliesItems[j]?.lessons?.length) {
//             prevAssembly = assembliesItems[j];
//             break;  // Exit the loop once a previous assembly with lessons is found
//           }
//         }
        
//         // If a previous assembly with lessons is found, set data
//         if (prevAssembly) {
//           const lastLessonIndex = prevAssembly.lessons.length - 1;
//           let x = 0;
          
//           while (!prevAssembly?.lessons[lastLessonIndex - x].sub_children.length && 
//                  prevAssembly?.lessons[lastLessonIndex - x].item_type == 3) {
//             x++;
//             if (prevAssembly?.lessons[lastLessonIndex - x].sub_children.length) {
//               break;
//             }
//           }
          
//           console.log(">>>>>>>>>>>>>>", prevAssembly.lessons, x);
//           setCurrentCategoryData(prevAssembly.lessons);
//           setModuleId(prevAssembly.lessons[lastLessonIndex - x]?.parent_id);
//           return prevAssembly.lessons[lastLessonIndex - x];
//         }
//       }
//     }
    
//     return null;
// };

  const updatePrevActivity = (prevActivity) => {
    if (!prevActivity) return;
    // Always update the current activity and lesson type
    dispatch(updateCurrentActivity(prevActivity?.item_id));
    dispatch(UpdateLessonType(prevActivity?.item_type));

    // Handle sub-category state
    if (prevActivity?.sub_children?.length) {
      setSubCategoryAcc({ state: true, id: prevActivity?.item_id });
    } else {
      setSubCategoryAcc({ state: false, id: null });
    }
  }

  const handlePrevActivity = () => {
    dispatch(updateActivityBtnAction('back'))
    const prevLesson = findPrevAssemblyLesson();
    if (prevLesson) {
      updatePrevActivity(prevLesson);
    }
  }

  // Update currentCategoryData when lessonList or currentActivity changes
  useEffect(() => {
    if (!lessonList?.assembliesItems?.length) return;

    const assembliesItems = lessonList.assembliesItems;
    
    for (const assembly of assembliesItems) {
      const lessons = assembly?.lessons || [];

      
      for (const lesson of lessons) {
        if (lesson?.item_id === currentActivity) {
          setCurrentCategoryData(lessons);
          return;
        }
        
        const subChildren = lesson?.sub_children || [];
        if (subChildren.some(subChild => subChild?.item_id === currentActivity)) {
          setCurrentCategoryData(subChildren);
          return;
        }
      }
    }
  }, [lessonList, currentActivity]);

  // Update currentLesson when currentActivity or currentCategoryData changes
  useEffect(() => {
    const currentLessonData = currentCategoryData.find(
      lesson => lesson?.item_id === currentActivity
    ) || currentCategoryData?.flatMap(
      lesson => lesson?.sub_children || []
    ).find(
      sub => sub?.item_id === currentActivity
    );

    setCurrentLesson(currentLessonData || null);
  }, [currentCategoryData,currentActivity]);

  // Render
  const renderContent = () => {
    if (!currentLesson) {
      return (
        <div className="learning-material-listing no__content__section" role="tablist" aria-multiselectable="true">
          <div className="subject-header">
            <div className="subject-sub-header ">
              <h3>No Content Available.</h3>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="learning-material-listing learning-material-content">
        <div className="learning-material-body">
          <ul className="sub-ul">
            {/* <ScrollBar> */}
              <AssembliesItems
                {...{
                  handleNextActivity:handleNextActivity,
                  handlePrevActivity,
                  setUpdateLearningMaterialData:setUpdateLearningMaterialData,
                  subassembliesItems,
                  subjectId,
                  assembliesItems: assemblyData,
                  isAdmin,
                  parentSubId: subjectId,
                  setQuizId,
                  QuizId,
                  setrId,
                  rId,
                  show,
                  lesson: currentLesson,
                  setshow,
                  parentSetCurrentVideoId: setCurrentVideoId,
                  lessonindex: currentLesson.item_id,
                  parentFileTracking: handleFileTracking,
                  currentVideoId,
                  getSubContentMethod,
                  buttonCheckedStatus,
                  documentId,
                  handleNextActivity,
                  lessonList
                }}
              />
            {/* </ScrollBar> */}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderContent()}
    </>
  );
};

export default memo(SubjectAssemblies);
