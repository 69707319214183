import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import ReactPlayer from "react-player/lazy";
import Loader from "react-loader-spinner";
import screenfull from "screenfull";
import { APP_LINK } from "../../utils/Constants";
import Swal from "sweetalert2";
import CreateNotesLessson from "./CreateNotesLessson";
import LessonNotesList from "./LessonNotesList";
import ReactHtmlParser from "react-html-parser";
import { FetchFileIcon } from "../../utils/commonFunction";
import { image_url } from "../../utils/ImageUrl";
import { download } from "../../utils/FileDownload";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { updateVideoPlayer } from "../../store/actions";
import UpdateAssembliesNote from "./UpdateAssembliesNote";
import { ref } from "yup";
import moment from "moment";
import { Scrollbar } from "react-scrollbars-custom";
import SekletonLearningMaterial from "../../loaders/SekletonLearningMaterial";
import SekletonVideoPlayer from "../../loaders/SkeletonVideoPlayer";

export default function VideoPlayer(props) {
  const {
    ParentResumeBtn,
    playing,
    videoId,
    lessonItemId,
    loopId,
    documentId,
    assemblyId,
    assemblyName,
    assemblyDescription,
    parentRefId,
    watchedVideo,
    url,
    item_url,
    videoType,
    video_duration,
    watch_in_m_s,
    handleNextActivity,
    currentVideoId,
    playerSeek,
    alreadyWatched,
    watchPercentage,
    isWatchedVideo,
    lessonindex,
    ParentHandleVideoInProgress,
    ParentPlayerPipMode,
    isAdmin,
    transcription,
    videoViewRef,
    setPlayerSeek,
    setPlaying,
    videoState,
    videoDuration,
    setUpdateLearningMaterialData,
    buttonConfig,
    prevButtonConfig,
    lastLessonId,
    currentActivity,
    handlePrevActivity,
    startLessonId
  } = props;


  let { parentRef } = props;
  const dispatch = useDispatch()
  let playerRef = useRef({});
  let previewRef = useRef({});
  let player = {};
  const [tab, setTab] = useState("description");
  const [addNotes, setAddNotes] = useState(false)
  const [buttonCheckedStatus, setButtonCheckedStatus] = useState(0);
  const videoPlayerShow = useSelector(state => state.learningMaterial.videoPlayer);
  const [isMinimized, setIsMinimized] = useState(false);
  const [videoPlay, setVideoPlay] = useState(Date.now())
  const [speed, setSpeed] = useState(1); // Default speed: 1x
  const [minimized, setMinimized] = useState(false);
  const [refresh, setRefresh] = useState(Date.now())
  const [currentTime, setCurrentTime] = useState(0);
  const [editNote, setEditNote] = useState(false)
  const [editNoteId, setEditNoteId] = useState("0")
  const [volume, setVolume] = useState(0.8);
  const [muted, setMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(playing || false);
  const [pause, setPause] = useState(null)
  const [showSettings, setShowSettings] = useState(false);
  const [quality, setQuality] = useState('auto');
  const [previousVolume, setPreviousVolume] = useState(0.8);
  const [showControls, setShowControls] = useState(true);
  const [formattedTime, setFormattedTime] = useState("00:00 / 00:00");
  const [showCaptions, setShowCaptions] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [previewTime, setPreviewTime] = useState(0);
  const [previewUrl, setPreviewUrl] = useState('');
  const previewPlayerRef = useRef(null);
  const [playerLoading, setPlayerLoading] = useState(true)
  const [isMobile, setIsMobile] = useState(null);
  const [progressInterval, setProgressInterval] = useState(null);
  
  // const hideControlsAfterDelay = () => {
  //   if (controlsTimeoutRef.current) {
  //     clearTimeout(controlsTimeoutRef.current);
  //   }
  //   controlsTimeoutRef.current = setTimeout(() => {
  //     if (isPlaying) {
  //       setShowControls(false);
  //     }
  //   }, 3000); // Hide controls after 3 seconds of inactivity
  // };

  // useEffect(() => {
  //   if (isPlaying) {
  //     hideControlsAfterDelay();
  //   } else {
  //     setShowControls(true);
  //   }

  //   return () => {
  //     if (controlsTimeoutRef.current) {
  //       clearTimeout(controlsTimeoutRef.current);
  //     }
  //   };
  // }, [isPlaying]);

  // const handleSpeedChange = (newSpeed) => {
  //   setSpeed(newSpeed);
  //   if (playerRef.current[parentRefId]) {
  //     const player = playerRef.current[parentRefId].getInternalPlayer();
  //     if (player) {
  //       player.playbackRate = newSpeed;
  //     }
  //   }
  // };
  // const toggleSettings = () => {
  //   setShowSettings(!showSettings);
  // };

  // const handleQualityChange = (newQuality) => {
  //   setQuality(newQuality);
  //   // Implement quality change logic here
  //   // This might involve reloading the video with a different quality URL
  // };

  useEffect(()=>{

  },[])

  useEffect(() => {
    if (playerRef.current[parentRefId]) {
      const player = playerRef.current[parentRefId].getInternalPlayer();
      if (player) {
        player.playbackRate = speed;
      }
    }
  }, [speed, parentRefId]);

  // const toggleMinimized = () => {
  //   setMinimized(!minimized);
  // };
  const { sid,password} = useParams()

  // const handleSpeedChange = (e) => {
  //   const newSpeed = parseFloat(e.target.value);
  //   setPlaybackRate(newSpeed); 
  //   if (playerRef[parentRefId] && playerRef[parentRefId].getInternalPlayer()) {
  //     playerRef[parentRefId].getInternalPlayer().playbackRate = newSpeed;  // Ensure that speed is directly applied
  //   }
  // };

  const controlsTimeoutRef=useRef({})
  const hideControlsAfterDelay = () => {
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    controlsTimeoutRef.current = setTimeout(() => {
      if (isPlaying) {
        setShowControls(false);
      }
    }, 3000); // Hide controls after 3 seconds of inactivity
  };

  useEffect(() => {
    if (isPlaying) {
      hideControlsAfterDelay();
    } else {
      setShowControls(true);
    }

    return () => {
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }
    };
  }, [isPlaying]);

  // const handleSpeedChange = (newSpeed) => {
  //   setSpeed(newSpeed);
  //   if (playerRef.current[parentRefId]) {
  //     const player = playerRef.current[parentRefId].getInternalPlayer();
  //     if (player) {
  //       player.playbackRate = newSpeed;
  //     }
  //   }
  // };
  // const toggleSettings = () => {
  //   setShowSettings(!showSettings);
  // };

  // const handleQualityChange = (newQuality) => {
  //   setQuality(newQuality);
  //   // Implement quality change logic here
  //   // This might involve reloading the video with a different quality URL
  // };


  useEffect(() => {
    if (playerRef.current[parentRefId]) {
      const player = playerRef.current[parentRefId].getInternalPlayer();
      if (player) {
        player.playbackRate = speed;
      }
    }
  }, [speed, parentRefId]);


  // const handleSpeedChange = (e) => {
  //   const newSpeed = parseFloat(e.target.value);
  //   setPlaybackRate(newSpeed); 
  //   if (playerRef[parentRefId] && playerRef[parentRefId].getInternalPlayer()) {
  //     playerRef[parentRefId].getInternalPlayer().playbackRate = newSpeed;  // Ensure that speed is directly applied
  //   }
  // };


  // const handleVideoStart = (e, player, assemblyItem, videoId) => {
  //   e.preventDefault();
  //   props.ParentHandleVideoStart(player, assemblyItem, videoId);
  // };

  const handleVideoResumeRP = (player, assemblyItem, videoId, action) => {
    // playerRef.current.seekTo(playerSeek, 'seconds');
    if(!password){
      props.ParentHandleVideoResume(player, assemblyItem, videoId, action);
    }
  };

  // const handleVideoRewind = (player, assemblyItem, videoId, rewindSec) => {
  //   if (rewindSec > 0) {
  //     let setSeek = parseFloat(playerSeek) - parseFloat(rewindSec);
  //     if (setSeek > 0) {
  //       props.ParentHandleVideoRewind(player, assemblyItem, videoId);
  //       player.seekTo(parseFloat(setSeek));
  //     } else {
  //       props.ParentHandleVideoStart(player, assemblyItem, videoId);
  //     }
  //   }
  // };

  // const handleVideoForward = (player, assemblyItem, videoId, forwardSec) => {
  //   if (forwardSec > 0) {
  //     let currentSeek = player.getCurrentTime();
  //     let setSeek = parseFloat(currentSeek) + parseFloat(forwardSec);
  //     if (setSeek > 0) {
  //       props.ParentHandleVideoForward(player, assemblyItem, videoId);
  //       player.seekTo(setSeek);
  //     }
  //   }
  // };

  const handleVideoReady = (e, player, assemblyItem, videoId) => {
    e.preventDefault();
    props.ParentHandleVideoReady(player, assemblyItem, videoId);
    dispatch(updateVideoPlayer(true))
  };

  // const handleVideoFullScreen = (e, player, assemblyItem, videoId) => {
  //   e.preventDefault();
  //   screenfull.request(ReactDOM.findDOMNode(player));
  // };

  // const handleVideoTogglePIP = (player, assemblyItem, videoId, action) => {
  //   props.ParentHandleVideoTogglePIP(player, assemblyItem, videoId, action);
  // };

  // const handleStop = () => {
  //   // this.setState({ url: null, playing: false })
  //   // console.log("stop");
  //   // console.log("Current time",player.current.getCurrentTime())
  //   // console.log("duration time",player.current.getDuration())

  //   // console.log(player);
  // };

  // const handlePlay = () => {
  //   // console.log("onPlay");
  //   // this.setState({ playing: true })
  // };
//   const handleProgress = (e, player, assemblyItem, videoId) => {
//     const currentTime = playerRef.current[parentRefId]?.getCurrentTime() || 0;
//     const duration = playerRef.current[parentRefId]?.getDuration() || 0;
    
//     // Format the current time and duration
//     const formattedCurrentTime = moment.utc(currentTime * 1000).format("mm:ss");
//     const formattedDuration = moment.utc(duration * 1000).format("mm:ss");
    
//     // Set the formatted time
//     setFormattedTime(`${formattedCurrentTime} / ${formattedDuration}`);
    
//     // Call any other necessary handlers
//     props.ParentHandleVideoInProgress(playerRef.current[parentRefId], parentRefId, loopId);
// };
  //   const handleProgress = (e, player, assemblyItem, videoId) => {
  //     const currentTime = playerRef.current[parentRefId]?.getCurrentTime() || 0;
  //     const duration = playerRef.current[parentRefId]?.getDuration() || 0;

  //     // Format the current time and duration
  //     const formattedCurrentTime = moment.utc(currentTime * 1000).format("mm:ss");
  //     const formattedDuration = moment.utc(duration * 1000).format("mm:ss");

  //     // Set the formatted time
  //     setFormattedTime(`${formattedCurrentTime} / ${formattedDuration}`);

  //     // Call any other necessary handlers
  //     props.ParentHandleVideoInProgress(playerRef.current[parentRefId], parentRefId, loopId);
  // };

  // const handleEnded = (e, player, assemblyItem, videoId) => {
  //   e.preventDefault();
  //   // console.log("onEnded");
  //   // this.setState({ playing: this.state.loop })
  // };


  //  const handleClickFullscreen = () => {
  //    screenfull.request(findDOMNode(player))
  //  }

  //  const ref = (ply) => {
  //    player = ply;
  //  }

  // const downloadFile = async (absolutePath, fileName, itemId) => {
  //   if (isAdmin != 1) {
  //     props.parentFileTracking(itemId);
  //   }
  //   let value = await download(absolutePath, fileName);
  // };

  // const handleToggleCaptions = () => {
  //   setShowCaptions(prev => !prev);
  // };


  // useEffect(() => {
  //   const handlePlay = () => {
  //     playerRef.current[parentRefId].seekTo(playerSeek); 
  //     playerRef.current[parentRefId]?.getInternalPlayer()?.play(); 
  //   };


  //   if (videoPlay && playerRef.current[parentRefId]) {
  //     handlePlay(); 
  //   }
  // }, [videoPlay, parentRefId]);

  // Handle video ready
  const onReady = () => {
    setPlayerLoading(false); // Set loading to false once the video is ready
    playerRef.current[parentRefId].seekTo(watch_in_m_s / 1000);
    playerRef.current[parentRefId].play();  
    handleVideoReady(playerRef.current[parentRefId], parentRefId, loopId);
  };

  // Handle play
  const onPlay = () => {
    setIsPlaying(true);
    handleVideoResumeRP(playerRef.current[parentRefId], parentRefId, loopId, true);
    
    const intervalId = setInterval(() => {
      const player = playerRef.current[parentRefId];
      const watchedVd = Math.round(player.getCurrentTime());
      const duration = Math.round(player.getDuration());
      
      if (watchedVd >= duration) {
        clearInterval(intervalId);
      } else {
        props.ParentHandleVideoInProgress(player, parentRefId, videoId);
      }
    }, 2000);

    // Store the interval ID
    setProgressInterval(intervalId);

    return () => {
      clearInterval(intervalId);
    };
  };

  useEffect(() => {
    if (tab == "description") {
      setAddNotes(false)
    }
  }, [tab])

  // const handlePlayPause = () => setPlaying(!playing);
  // const handleSeek = (time) => playerRef.current.seekTo(time);
  // const handleSpeedChange = (speed) => {
  //   if (playerRef.current) {
  //     playerRef.current.playbackRate = speed;
  //   }
  // };

  // const handleMouseMove = (e) => {
  //   if (!duration) return;

  //   const { left, width } = e.currentTarget.getBoundingClientRect();
  //   const offsetX = e.clientX - left;
  //   const percentage = offsetX / width;
  //   const previewTime = percentage * duration;

  //   setPreviewTime(previewTime);
  //   setPreviewVisible(true);

  //   if (previewPlayerRef.current) {
  //     previewPlayerRef.current.seekTo(previewTime, 'seconds');
  //   }
  // };

  useEffect(() => {
    // Set the preview URL when the component mounts or when the video URL changes
    if (videoType === 0) { // Vimeo
      setPreviewUrl(`https://player.vimeo.com/video/${item_url}`);
    } else { // YouTube
      setPreviewUrl(`https://www.youtube.com/watch?v=${url}`);
    }
  }, [videoType, item_url, url]);


  // const handleToggleFullscreen = () => {
  //   if (screenfull.isEnabled) {
  //     screenfull.toggle(ReactDOM.findDOMNode(playerRef.current[parentRefId]));
  //   }
  // };

  // const handleVolumeChange = (newVolume) => {
  //   setVolume(newVolume);
  // };

  useEffect(() => {
    if (playerRef.current[parentRefId]) {
      const player = playerRef.current[parentRefId].getInternalPlayer();
      if (player && typeof player.setVolume === 'function') {
        player.setVolume(volume);
      }
    }
  }, [volume, parentRefId]);

  // const toggleMute = () => {
  //   if (muted) {
  //     setVolume(previousVolume);
  //     setMuted(false);
  //   } else {
  //     setPreviousVolume(volume);
  //     setVolume(0);
  //     setMuted(true);
  //   }
  // };

  const handleProgress = (progress) => {
    setCurrentTime(progress.playedSeconds);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  // const handleMouseLeave = () => {
  //   setPreviewVisible(false);
  // };
  const resumeFromTime = (timeInSeconds) => {
    if (playerRef.current[parentRefId]) {
      playerRef.current[parentRefId].seekTo(timeInSeconds);
      // playerRef.current[parentRefId].play();
      props.ParentHandleVideoResume(playerRef.current[parentRefId], parentRefId, loopId, true);
    }
  };    


  // Function to update state based on screen size
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  useEffect(() => {
    if (!isPlaying) {
      clearInterval(progressInterval);
      handleVideoResumeRP(playerRef.current[parentRefId], parentRefId, loopId, false);
      setProgressInterval(null);
    }
    console.log("progressInterval", progressInterval);
    console.log("isPlaying", isPlaying);
  }, [isPlaying]);

  return (                            
    <>
      <div className="card-list-inner">
        <div className={`row rowlec ${minimized ? "d-flex flex-row" : ""}`} style={{ marginLeft: "0" }}>
          <div className={`${minimized ? "col-xl-6" : "col-lg-12"} lec-sec-left video__container`} style={{ paddingLeft: "0" }}>
            {playerLoading ?  <div className="video__loader"><SekletonVideoPlayer /></div> : null}
            <div className={`vidbox ${playerLoading ? "d-none" : "updateWatchCountService"}`} title={`${`${assemblyName.includes(": ") ? assemblyName.split(": ")[1] : assemblyName}`}`}>
                {videoType == 0 ? (
                  //   Vimeo
                  <ReactPlayer
                    url={`https://player.vimeo.com/video/${item_url}#t=${watch_in_m_s}`}
                    // url={`https://vimeo.com/${item_url}`}
                    onError={(err)=>{
                      console.log(err.message)
                      if(err.message.toString().toLowerCase().includes("not found")){
                        Swal.fire({
                          icon:"error",
                          title:"Video Not Found",
                          text:"This Video Doesn't Exists anymore."
                        })
                      }
                    }}
                    ref={(ref) => (playerRef.current[parentRefId] = ref)}
                    onReady={onReady}
                    // onReady={() => console.log("onReady")}
                    // onStart={() => console.log("onStart")}
                    onPlay={isAdmin != 1 && onPlay}
                    onPause={() => {
                      if(isAdmin != 1){
                        setIsPlaying(false);
                        handleVideoResumeRP(playerRef.current[parentRefId], parentRefId, loopId, false);
                      }
                    }}
                  onEnded={() => {
                    if (isAdmin != 1) {
                      setIsPlaying(false);
                      handleVideoResumeRP(
                        playerRef.current[parentRefId],
                        parentRefId,
                        loopId,
                        null
                      );
                    }
                    }}
                    // light={
                    //   (loopId == currentVideoId && playing == true) ||
                    //   watchedVideo.includes(loopId) == true
                    //     ? false
                    //     : true
                    // }
                    // light={
                    //   loopId == currentVideoId && playing == true ? false : true
                    // }
                    pip={ParentPlayerPipMode}
                    stopOnUnmount={false}
                    className="react-player"
                    playing={
                      loopId == currentVideoId && playing == true ? true : false
                    }
                    width='100%'
                    height='100%'
                    progressInterval={2000}
                    onDuration={handleDuration}
                    onProgress={(state) => {
                      if (isAdmin != 1) {
                        setUpdateLearningMaterialData({
                          state: true,
                          id: assemblyId
                        });
                        // if (playerRef.current[parentRefId]) {
                        handleProgress(state, playerRef.current[parentRefId], parentRefId, loopId);
                      }
                    }}
                    controls={true}
                  />
                  ) : (
                  // Youtube
                  <ReactPlayer
                    url={`https://www.youtube.com/watch?v=${url}#t=${watch_in_m_s}`}
                    ref={(ref) => (playerRef.current[parentRefId] = ref)}
                    // onReady={() => playerRef[parentRefId].seekTo(playerSeek)}
                    onReady={onReady}
                    // onReady={() => console.log("onReady")}
                    // onStart={() => console.log("onStart")}
                    onPlay={isAdmin != 1 && onPlay}
                    onPause={() => {
                      if(isAdmin != 1){
                        setIsPlaying(false);
                        handleVideoResumeRP(playerRef.current[parentRefId], parentRefId, loopId, false);
                      }
                    }}
                    onEnded={() => {
                      if (isAdmin != 1) {
                        setIsPlaying(false);
                        handleVideoResumeRP(
                          playerRef.current[parentRefId],
                          parentRefId,
                          loopId,
                          null
                        );
                      }
                    }}
                    // light={
                    //   loopId == currentVideoId && playing == true
                    //     ? ""
                    //     : `https://img.youtube.com/vi/${url}/0.jpg`
                    // }
                    // pip={ParentPlayerPipMode}
                    stopOnUnmount={false}
                    className="react-player"
                    playing={
                      loopId == videoId && playing == true ? true : false
                    }
                    width="100%"
                    height="100%"
                    progressInterval={2000}
                    onProgress={(state) => {
                      if (isAdmin != 1) {
                        setUpdateLearningMaterialData({
                          state: true,
                          id: assemblyId
                        });
                        // if (playerRef.current[parentRefId]) {
                        handleProgress(state, playerRef.current[parentRefId], parentRefId, loopId);
                      }
                    }}
                    controls={true}
                    youtube="youtube"
                  />
                )}
                
              </div>
          </div>

          <div className={`${minimized ? "col-xl-6" : "col-lg-12"} lec-sec-right px-0 video__container`}>
            <div className="lesson-video-bottom">
              {!minimized ? <div className="lec-sec-text-body" style={{ padding: "10px 0 0 0" }}>
                <p title={`${assemblyName.includes(": ") ? assemblyName.split(": ")[1] : assemblyName}`} className="m-0"><b>{assemblyName.includes(": ") ? assemblyName.split(": ")[1] : assemblyName}</b></p>
              </div> : null}
              <div className="lecture-detail-bottom">
                <div className="lecture-tabs-wrap lesson-tabs-wrap">
                  <ul className="nav nav-pills course-nav-sticky m-0 p-0" id="pills-tab" role="tablist">
                    <li className="nav-item " onClick={() => setTab("description")} title="Description">
                      <span className={`nav-link ${tab == "description" ? "active" : ""}`}
                      >
                        Description
                      </span>
                    </li>
                    <li className="nav-item " onClick={() => setTab("notes")} title="My Notes">
                      <span className={`nav-link ${tab == "notes" ? "active" : ""}`}
                      >
                        My Notes
                      </span>
                    </li>
                    {/* <li className="nav-item " onClick={() => setTab("transcription")}>
                        <span className={`nav-link ${tab == "transcription" ? "active" : ""}`}
                        >
                          Transcription
                        </span>
                      </li> */}
                    <li
                      className="add-note-button"
                    >
                      <span className={`btn note__btn ${isAdmin == 1 && 'disable'}`}
                        title="Add Notes"
                        onClick={() => {
                          if(tab == "description"){
                            setTab("notes");
                          }
                          setAddNotes(true);
                          handleVideoResumeRP(playerRef.current[parentRefId], parentRefId, loopId, false);
                        }}>
                        <i className="fal fa-edit" />
                        Add Notes
                      </span>
                    </li>

                  </ul>
                </div>
                {/* <Scrollbar className="scroll-course-list"> */}
                {tab === "description" && (
                  <p className="video__desc__content" title='video description'> {assemblyDescription ? ReactHtmlParser(assemblyDescription): null}</p>
                )}
                {tab === "notes" && (
                  <> 
                    {((minimized || (!minimized && !addNotes)) && isAdmin !== 1) && (
                      <LessonNotesList
                        lessonItemId={lessonItemId}
                        subjectId={sid}
                        closeAddNotes={setAddNotes}
                        timestamp={videoState}
                        setPlayerSeek={setPlayerSeek}
                        // onPlay={onplay()}
                        // resumeVideoPlayer={handleVideoResumeRP(playerRef.current[parentRefId], parentRefId, loopId, true)}
                        setVideoPlay={setVideoPlay}
                        setEditNoteId={setEditNoteId}
                        editNoteId={editNoteId}
                        setEditNote={setEditNote}
                        editNote={editNote}
                        minimized={minimized}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        resumeFromTime={resumeFromTime}
                      />
                    )}
                    {!minimized && addNotes && (
                      <CreateNotesLessson
                        props={props}
                        closeAddNotes={setAddNotes}
                        subjectId={sid}
                        timestamp={videoState}
                        setRefresh={setRefresh}
                        refresh={refresh}
                      />
                    )}
                  </>
                )}
                {/* </Scrollbar> */}
                {/* {tab === "transcription" && (
                    <div>{ReactHtmlParser(transcription && transcription !== "" ? (transcription) : "")}</div>
                  )} */}

                {/* {(lastLessonId !== currentActivity && buttonConfig.show) ? <div className="quiz-footer-btns">
                  <button className="btn btn-white-bordered btn-primary next-activity" onClick={handleNextActivity}>
                    <span>Continue</span><i className="fal fa-chevron-right"></i>
                  </button>
                </div> : null} */}

                <div className="learning__bottom__btn">
                  {startLessonId !== currentActivity && prevButtonConfig.show && <div className="footer-left-btn"> <button className="btn btn-white-bordered btn-primary prev-activity" onClick={handlePrevActivity}>
                    <i className="fal fa-chevron-left"></i><span>Back</span>
                  </button> </div>}
                  {(lastLessonId !== currentActivity && buttonConfig.show) ? <div className="footer-right-btn">
                    <button className="btn btn-white-bordered btn-primary next-activity" onClick={handleNextActivity}>
                      <span>Continue</span><i className="fal fa-chevron-right"></i>
                    </button> </div> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Scrollbar> */}
      </div>
    </>
  );
}
