import React from "react";
//import $ from "jquery";
/* eslint-disable import/first */
var $ = require("jquery");
//require("jquery-mousewheel")($);
//require("malihu-custom-scrollbar-plugin")($);
import { Scrollbars } from "react-custom-scrollbars";
import { Scrollbar } from "react-scrollbars-custom";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import QualificationHeadBox from "../components/course/QualificationHeadBox";
import SubjectListBox from "../components/course/SubjectListBox";
import SubjectContent from "../components/course/SubjectContent";
import { connect } from "react-redux";
import { clearToggles, setCount, setSubject, updateIsDownloadAssessment,updateSubjectContentLoadingOnLesson } from "../store/actions";
import {
  GetMySubjectsService,
  GetSubjectContentService,
  GetSubjectsService,
} from "../services/QualificationService";
import { getToken } from "../utils/Auth";
/** WEB SOCKET  */
// import { WS_URL } from "../utils/Constants";
// import Ws from "@adonisjs/websocket-client";
import SkeletonSubject from "../loaders/SkeletonSubject";
import SkeletonQaulificationBox from "../loaders/SkeletonQualificationBox";
import not_yet_published_icon from "../assets/images/not_yet_published_icon.svg";
import SekletonSubjectList from "../loaders/SekletonSubjectList";

import moment from "moment-timezone";
import SkeletonSubjectHeadBox from "../loaders/SekletonSubjectHeadBox";
import SubjectHeadBox from "../components/course/SubjectHeadBox";
import { math } from "@tensorflow/tfjs";
import PastSubjectList from "../components/course/PastSubjectList";
import SubjectAssemblies from "../components/course/SubjectAssemblies";
import { GetLearningMaterial, GetLearningMaterialAdmin } from "../services/AssembliesService";
import { match } from "assert";
import LeasonListLearningMaterial from "../components/course/LeasonListLearningMaterial";
import { tr } from "date-fns/locale";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
// moment.tz.setDefault("Africa/Johannesburg");
let ws = null;
//let chat = null;
/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setSubject: (payload) => dispatch(setSubject(payload)),
    setCount: (payload) => dispatch(setCount(payload)),
    isAssessmentDownloadBtn: (payload) => dispatch(updateIsDownloadAssessment(payload)),
    updateSubjectContentLoadingOnLesson: (payload) => dispatch(updateSubjectContentLoadingOnLesson(payload)),
  };
}
function mapStateToProps(state) {
  return {
    subjectContentLoadingOnLesson: state.learningMaterial.subjectContentLoadingOnLesson
  };
}

class MyCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgress:false,
      responseData: {},
      learningTab:false,
      subjects: [],
      completed_subjects: [],
      subjectContent: {},
      chat: {},
      loading: false,
      subjectContentLoading:true,
      active: 0,
      initialClick: false,
      subjectId: 0,
      reload: 0,
      subjectListLoading: true,
      reduceCommentCount: 0,
      room_id: 0,
      isExamLaunched:false,
      assessmentId: 0,
      backToOverview : 0,
      programId:0,
      QuizId: "",
      rId:"",
      assemblies:[],
      moduleId:"",
      mounted:false,
      nextModuleId:0,
      currentLesson:"",
      // showNextLesson:false,
      subassembliesItems:[],
      activeTab:"",
      currentModuleId:"",
      nextModuleId:"",
      assessmentActive:false,
      assembliesItems:[],
      assemblyCategoryList :[],
      lessonList:[],
      show: {
        cards: true,
        list: false,
        desc: false,
        question: false,
        review: false,
        attempt: false,
      },
      showLearningOrAssessment:false
    };
    this.showCompleted = React.createRef();
  }

  updateShowState = (newValues) => {
    this.setState((prevState) => ({
      show: {
        ...prevState.show,
        ...newValues,
      },
    }));
  };

  updateLearningAssessment=(value)=>{
    this.setState({showLearningOrAssessment:value})
  }

  componentDidMount() {
    this.props.setCount(0);
    this.props.updateSubjectContentLoadingOnLesson('no loading')
    $("body").addClass("sub-sidebar-c");
    $("body").addClass("hide-subject-sidebar");
    
    const { match } = this.props;
    
    if (match?.params?.aid && match?.params?.aid != undefined && match?.params?.aid != 0) {
      this.setState({ assessmentId: match.params.aid });
    }
    this.setState({ loading: true });
    if (this.props.location.pathname.includes("shortCourse")) {
      this.getSubContentMethod(match.params.sid);
      this.setState({ programId: match.params.sid });
      this.setState({ subjectId: match.params.sid });
      // this.setState({ subjectListLoading: t });
      // this.setState({ loading: false });
    } else {
      GetMySubjectsService(match.params)
        .then((res) => {
          if (res.data) {
            if (
              res.data?.subject?.others.length <= 0 ||
              res.data?.subject?.finised.length > 0
            ) {
              this.setState({ initialClick: true });
            }
            let initialID;
            if (match.params.sid == undefined) {
              initialID =
                res.data?.subject?.others?.length > 0
                  ? res.data?.subject?.others[0].id
                  : res.data?.subject?.finised?.length > 0
                    ? res.data?.subject?.finised[0].id
                    : 58;
            } else {
              initialID = match.params.sid;
            }
            this.getSubContentMethod(initialID);
            this.setState({ subjectId: initialID });
            this.setState({
              responseData: res.data,
              subjects: res.data?.subject?.others,
              completed_subjects: res.data?.subject?.finised,
            });
            this.setState({ subjectListLoading: false });
            setTimeout(() => {
              this.setState({ loading: false });
            }, 1000);
          } else {
            this.handleRedirectToCourse()
          }
        })
        .catch((error) => {
          this.handleRedirectToCourse();
          this.setState({ subjectListLoading: false });
        });
    }
    
  }

  

  updateSubjectOnly = () => {    
    const { match } = this.props;    
    GetMySubjectsService(match.params)
      .then((res) => {
        if(res.data){
          this.setState({
            qualification: res.data,
            subjects: res.data.subject.others,
            completed_subjects: res.data.subject.finised,
          });          
        }else{
          this.handleRedirectToCourse()
        }
      })
      .catch((error) => {
        this.handleRedirectToCourse();
      });
  };


  updateSubject = (isSLP) => {
    
    const { match } = this.props;
    if (isSLP) {
      GetSubjectsService({ qid: match.params.sid, isSLP, sp_student_intake_id: this.state?.subjectContent?.subject?.sp_student_intake_id })
        .then((res) => {
          this.setState({ subjectContent:  res.data });
        })
        .catch((err) => console.log(err));
    } else {
      GetMySubjectsService(match.params)
        .then((res) => {
          if(res.data){
            this.setState({
              qualification: res.data,
              subjects: res.data.subject.others,
              completed_subjects: res.data.subject.finised,
            });
            GetSubjectContentService({ sid: this.state.subjectId })
              .then((res) => {
                this.setState({ subjectContent: res.data });
              })
              .catch((error) => {
                this.handleRedirectToCourse();
              });
          }else{
            this.handleRedirectToCourse()
          }
        })
        .catch((error) => {
          this.handleRedirectToCourse();
        });
    }
  };




  getSubContentMethod = (sid) => {
    //debugger;  
    if(this.props.subjectContentLoadingOnLesson == 'loading'){
      this.setState({ subjectContentLoading: true});
    }
    if (this.state.active !== sid && sid) {
      // this.state.qualification.school_type !== "shortcourse" && $("body").removeClass("hide-subject-sidebar");
      $("body").removeClass("hide-subject-sidebar");
      this.setState({ subjectId: sid });
      
      GetSubjectContentService({ sid })
        .then((res) => {
          if(res.status==200){
            this.setState({ subjectContent: res.data
            });
            this.setState({ active: sid });
            if (this.state.initialClick) {
              if(this.showCompleted.current){
                this.showCompleted.current.click();
              }
              this.setState({ initialClick: false });
            }
            this.setState({ subjectListLoading: false });
            this.setState({ loading: false });

            //updating downloadAll button in assessemnt list
            this.props.isAssessmentDownloadBtn(res.data?.subject?.is_download_assessments);            
            this.setState({ subjectContentLoading: false });
          }else{
            this.handleRedirectToCourse()
          }
        })
        .catch((error) => {
          this.handleRedirectToCourse()
        });
    } else if (sid) {
      GetSubjectContentService({ sid })
        .then((res) => {
          if(res.status==200){
            this.setState({ subjectContent: res.data });
            this.setState({ active: sid });
            if (this.state.initialClick) {
              this.showCompleted.current.click();
              this.setState({ initialClick: false });
            }
            this.setState({ subjectContentLoading: false });
          }else{
            this.handleRedirectToCourse()
          }
        })
        .catch((error) => {
          this.handleRedirectToCourse()
        });
    }
  };

  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
    if ($("body").hasClass("hide-subject-sidebar")) {
      $("body").removeClass("sub-sidebar-c");
    } else {
      $("body").addClass("sub-sidebar-c");
    }
  };


  reduceCount = (count, room_id) => {
    this.props.setCount(this.state.reduceCommentCount + count);
    this.setState({ reduceCommentCount: this.state.reduceCommentCount + count, room_id: room_id })

    let tempSub = this.state.subjects
    let comTempSub = this.state.completed_subjects

    tempSub.map(i => {
      if (i.id == room_id) {
        i.unreadMessage = i.unreadMessage - count
      }
    })
    comTempSub.map(i => {
      if (i.id == room_id) {
        i.unreadMessage = i.unreadMessage - count
      }
    })
    this.setState({
      subjects: tempSub,
      completed_subjects: comTempSub,
    });
  }

  handleRedirectToCourse(){
    this.props.history.push("/mycourses")
  }


  setQuizId = (value) => {
  this.setState({ QuizId: value });
  }

  setrId = (value) => {
  this.setState({ rId: value });
  }

  render() {
    const { qid, sid } = this.props.match.params;
    return (
      <>
     <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
          <SideBar count={this.state.reduceCommentCount}></SideBar>
           <div className="sidebar-right">
            {this.state.subjectListLoading && <SekletonSubjectList />}

            {/* shortcourse learning material list */}
            {(this.state.responseData.length != 0 || this.props.location.pathname.includes("shortCourse")) && !this.state.subjectListLoading ? (
              <div className="subject-detail-wrap  h-100">
                {/* side bar */}
                {this.props.location.pathname.includes("shortCourse") && this.state.learningTab &&
                  <div className="sub-detail-lft bg-white">

                    <div className="sub-lft-body lesson-list-body">
                      <>
                        <div className="back-button-learn">
                          <a
                            href="#"
                            title="Back"
                            onClick={(event) => {
                              event.preventDefault(); 
                              this.setState(() => ({ learningTab: false }));
                              this.updateShowState({ cards: true, list: false });
                            }}
                          >
                            <i className="fal fa-angle-left"></i>Back
                          </a>
                          <button
                            className="close-btn-sidebar"
                            onClick={this.handleSideBar}
                          >
                            <i className="close-bar close-bar-1"></i>
                            <i className="close-bar close-bar-2"></i>
                            <i className="close-bar close-bar-3"></i>
                          </button>
                        </div>
                        <div className="learning-quiz-wrap h-100">
                          <LeasonListLearningMaterial
                            assembliesItems={this.state.assembliesItems}
                            setassembliesItems={(value) => this.setState({ assembliesItems: value })}
                            setSubassembliesItems={(value) => this.setState({ subassembliesItems: value })}
                            subassembliesItems={this.state.subassembliesItems}
                            currentLesson={this.state.currentLesson}
                            match={this.props.match}
                            moduleId={this.state.moduleId}
                            setModuleId={(value) => this.setState({ moduleId: value })}
                            setActiveTab={(value) => this.setState({ activeTab: value })}
                            setCurrentModuleId={(value) => this.setState({ currentModuleId: value })}
                            setNextModuleId={(value) => this.setState({ nextModuleId: value })}
                            assemblyCategoryList ={this.state.assemblyCategoryList }
                            lessonList={this.state.lessonList}
                            setLessonList={(value) => this.setState({ lessonList: value })}
                          />
                        </div>
                      </>
                    </div>
                  </div>
                }
                {!this.props.location.pathname.includes("shortCourse") && <div 
                    className={`sub-detail-lft bg-white  ${!this.props.location.pathname.includes("shortCourse") && this.state.learningTab 
                        ? "sub-detail-qualification" 
                        : ""
                    }`}
                  >  
                  {this.state.loading && <SkeletonQaulificationBox />}
                  {!this.state.loading && !this.props.location.pathname.includes("shortCourse") ?  (
                    <QualificationHeadBox show={this.state.show} updateLearningAssessment={this.updateLearningAssessment} setshow={this.updateShowState}  row={this.state} showProgress={this.state.showProgress} setShowProgress={(value) => this.setState({ showProgress: value })} learningTab={this.state.learningTab} subjectContent={this.state.subjectContent} setLearningTab={(value)=>this.setState({learningTab : value})}/>
                  ) : 
                  null
                  }
                  <div className="sub-lft-body lesson-sub-body">
                    {(!this.state.loading && !this.state.learningTab || (this.state.learningTab && this.state.show.cards)) && (
                      <h6 className='heading-title'>
                        {!this.state.assessmentActive ? "Modules" : "Programme Courses"}
                      </h6>
                    )}

                    {(!this.state.learningTab || (this.state.learningTab && this.state.show.cards)) && 
                      <Scrollbar
                      className={`${this.state.learningTab && !this.state.show.cards
                          ? "max-height-200"
                          : this.state.showProgress && this.state.showLearningOrAssessment && this.state.responseData?.timeTable =="" ? "max-height-800"
                            : this.state.showProgress && this.state.responseData?.timeTable === "" ? "max-height-700"
                              : this.state.responseData?.timeTable === ""
                                ? "max-height-900"
                                : this.state.responseData?.prescribed_book_buttton === 0 ? 'max-height-450'
                                : "max-height-400"
                        }`}
                    >                  
                      <div className="subject-list">
                        {this.state.loading && <SkeletonSubject />}
                        {(!this.state.loading && !this.state.learningTab || (this.state.learningTab && this.state.show.cards)) && (
                          <>
                            <SubjectListBox
                              rows={this.state.subjects}
                              parentGetSubContentMethod={this.getSubContentMethod}
                              reload={this.reload}
                              active={this.state.active}
                              qid={this.props.match.params.qid}
                              isExamLaunched={this.state.isExamLaunched}
                              setIsExamLaunchedToTrue={() => this.setState({ isExamLaunched: true })}
                              setIsExamLaunchedToFalse={() => this.setState({ isExamLaunched: false })}
                              changeSub={() => this.setState({ backToOverview: this.state.backToOverview + 1 })}
                            />
                            <PastSubjectList
                              rows={this.state.completed_subjects}
                              parentGetSubContentMethod={this.getSubContentMethod}
                              active={this.state.active}
                              qid={this.props.match.params.qid}
                              changeSub={() => this.setState({ backToOverview: this.state.backToOverview + 1 })}
                            />
                          </>
                        )}
                      </div>
                      {/* <div id="spacer">&nbsp;</div> */}
                    </Scrollbar>}

                    {!this.state.loading && this.state.learningTab && !this.state.show.cards &&
                      // <div className="tab-pane fade show active" id="pills-Assemblies" role="tabpanel" aria-labelledby="pills-Assemblies-tab">
                        <>
                          <div className="learning-quiz-wrap h-100">
                            <LeasonListLearningMaterial
                              assembliesItems={this.state.assembliesItems}
                              assemblyCategoryList ={this.state.assemblyCategoryList }
                              setassembliesItems={(value) => this.setState({ assembliesItems: value })}
                              setSubassembliesItems={(value) => this.setState({ subassembliesItems: value })}
                              subassembliesItems={this.state.subassembliesItems}
                              currentLesson={this.state.currentLesson}
                              match={this.props.match}
                              moduleId={this.state.moduleId}
                              setModuleId={(value) => this.setState({ moduleId: value })}
                              setActiveTab={(value) => this.setState({ activeTab: value })}
                              setCurrentModuleId={(value) => this.setState({ currentModuleId: value })}
                              setNextModuleId={(value) => this.setState({ nextModuleId: value })}

                              lessonList={this.state.lessonList}
                              setLessonList={(value) => this.setState({ lessonList: value })}
                            />
                          </div>
                        </>
                      // </div>
                    }

                  </div>
                </div>}
                {/* end of side bar */}
                {this.state.subjectContentLoading && <SekletonSubjectList />}
                {!this.state.subjectContentLoading ? <div className={this.props.location.pathname.includes("shortCourse") && !this.state.learningTab ? "sub-detail-full" : "sub-detail-rgt"}>
                    {this.state.subjectContent.subject ? (
                      this.state.subjectContent.subject.view_only != "show" && 
                        this?.state?.subjectContent?.subject?.publish >
                        moment().unix()   ? (
                          <div className="blank-not-publish-con">
                          <div className="blank-chat-icon">
                            <i className="fad fa-times-square chat-icon-3"></i>
                            <i className="fad fa-upload chat-icon-4"></i>
                            <i className="fad fa-times-circle chat-icon-5"></i>
                          </div>
                          <div className="blank-chat-text">
                            <p>Course not yet published</p>
                            <p>Please try again closer to the start date</p>
                          </div>
                        </div>
                      ) : (
                        <SubjectContent
                          setshow={this.updateShowState}
                          show={this.state.show}
                          getSubContentMethod={() =>
                            this.getSubContentMethod(this.state.subjectId)
                          }
                          subjectId={this.state.subjectId}
                          isAdmin={0}
                          setLessonList={(value) => this.setState({ lessonList: value })}
                          lessonList={this.state.lessonList}
                           setAssemblyCategoryList={(value)=>this.setState({assemblyCategoryList :value})}
                          subjectContent={this.state.subjectContent}
                          updateSubject={this.updateSubject}
                          updateSubjectOnly={this.updateSubjectOnly}
                          reduceCount={this.reduceCount}
                          isExamLaunched={this.state.isExamLaunched}
                          setIsExamLaunchedToTrue={()=>this.setState({isExamLaunched:true})}
                          setIsExamLaunchedToFalse={()=>this.setState({isExamLaunched:false})}
                          assessmentId={this.state.assessmentId}
                          qid={this.props.match.params.qid}
                          params={this.props.match}
                          backToOverview={this.state.backToOverview}
                          handleLearningTab={this.handleLearningTab}
                          learningTab={this.state.learningTab}
                          setLearningTab={(value)=>this.setState({ learningTab: value })}
                          assemblyCategoryList ={this.state.assemblyCategoryList }
                          assemblies= {this.state.assemblies}
                          moduleId={this.state.moduleId}
                          setModuleId={(value) => this.setState({ moduleId: value })}
                          nextModuleId={this.state.nextModuleId}
                          subassembliesItems={this.state.subassembliesItems}
                          setSubassembliesItems={(value) => this.setState({ subassembliesItems: value })}
                          setAssessmentActive={(value)=>this.setState({assessmentActive:value})}
                          assembliesItems={this.state.assembliesItems}
                          setassembliesItems={(value)=> this.setState({assembliesItems:value})}
                        />
                      )
                    ) : null}

                </div> : null}
              </div>) : !this.state.subjectListLoading ? (
                <div className="subject-no-content">
                  <div className="no-content">
                    <h4>Unauthorized Access</h4>
                    <p>You don't have access to view this page.</p>
                  </div>
                </div>) : ('')}
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCourse);
